import styled from "styled-components";
import TableBarton from "../../../component/tables/TableBarton";
import Prices from "../../../component/prices/Prices";
import Documentation from "../../../component/documentation/Documentation";

export default function SolutionPisosIndustrialesView() {
    return (
        <>
            <StyleSolutionPisosIndustrialesView>
                <div className="banner">
                    <img src="./assets/img/soluciones/pisosindustriales/bannerPisosIndustrialesx400.jpg" alt="banner Solución de Pisos Industriales" />
                </div>
                <div className="container">
                    <h2>VENTAJAS DEL USO DE BARRAS REFORZADAS DE FIBRA DE VIDRIO EN OBRAS DE Pisos Industriales</h2>
                    <div className="containerBeneficioSolucion">
                        <div className="contenInfo">
                            <p>
                                <span>Resistencia a la corrosión: </span> Las barras de fibra de vidrio son altamente resistentes a la corrosión, lo que las hace ideales para pisos industriales expuestos a sustancias químicas agresivas, como ácidos o solventes. No se corroen ni se oxidan, lo que garantiza una mayor durabilidad y vida útil del piso.
                            </p>
                            <p>
                                <span>Mayor durabilidad: </span> Las barras de fibra de vidrio son extremadamente duraderas y pueden soportar cargas pesadas y tráfico intenso sin deformarse ni romperse. Esto las convierte en una opción adecuada para pisos industriales que deben soportar maquinaria pesada, equipos de movimiento y transporte de carga.
                            </p>
                            <p>
                                <span>Aislamiento térmico y eléctrico: </span> Las barras de fibra de vidrio tienen una baja conductividad térmica y eléctrica. Esto significa que no transmiten fácilmente el calor o la electricidad, lo que puede ser beneficioso en entornos industriales donde se requiere aislamiento térmico o se busca evitar riesgos eléctricos.
                            </p>
                            <p>
                                <span>Ligereza: </span> Las barras de fibra de vidrio son significativamente más ligeras que el hierro de construcción, lo que facilita su manipulación, transporte e instalación en el lugar de trabajo. Esto puede acelerar el proceso de construcción y reducir los costos asociados con la mano de obra y equipos de elevación.
                            </p>
                            <p>
                                <span>Resistencia a la fatiga: </span>Las barras de fibra de vidrio tienen una alta resistencia a la fatiga, lo que significa que mantienen su integridad estructural incluso cuando están sujetas a cargas cíclicas o repetitivas. Esto es especialmente beneficioso en pisos industriales que experimentan cargas dinámicas, como pisos de almacenes o áreas de producción con movimiento constante de maquinaria.
                            </p>
                            <p>
                                Es importante tener en cuenta que al utilizar barras de fibra de vidrio para pisos industriales, se deben considerar otros factores, como el diseño estructural, las cargas de servicio y las especificaciones locales y normativas.
                            </p>
                            <img src="./assets/img/soluciones/pisosindustriales/PisosIndustrialesx600.jpg" alt="imagen Solucion" />
                            <img src="./assets/img/soluciones/pisosindustriales/PisosIndustriales2x600.jpg" alt="imagen Solucion" />
                            <img src="./assets/img/soluciones/pisosindustriales/PisosIndustriales3x600.jpg" alt="imagen Solucion" />
                        </div>
                        <div className="contenBeneficioLogos">
                            <div className="beneficioSolucion">
                                <img src="./assets/img/benefits/corrosion.svg" alt="Anticorrosivo" />
                                <p>La corrosión no afecta a la estructura haciendola más duradera</p>
                            </div>
                            <div className="beneficioSolucion">
                                <img src="./assets/img/benefits/resistente.svg" alt="Resistente" />
                                <p>x3 veces más resistente</p>
                            </div>
                            <div className="beneficioSolucion">
                                <img src="./assets/img/benefits/dielectrico.svg" alt="Dieléctrico"/>
                                <p>Proporcionan un aislamiento eléctrico efectivo y minimizan el riesgo de descargas eléctricas</p>
                            </div>
                            <div className="beneficioSolucion">
                                <img src="./assets/img/benefits/liviano.svg" alt="MasLiviano" />
                                <p>Reduce 30% el peso final, haciendo una estructura mucho más liviana</p>
                            </div>
                        </div>
                    </div>
                </div>
            </StyleSolutionPisosIndustrialesView>
            <Documentation />
            <TableBarton />
            <Prices />
        </>
    )
}

const StyleSolutionPisosIndustrialesView = styled.div`
    h2{
        color: var(--colorTerciario);
        font-size: 2.2rem;
        margin: 2rem 0rem;
        @media (min-width: 768px){
            margin: 5rem 0rem;
            text-align: center;
            font-size: 2.8rem;
        }
    }
    .banner{
        margin-top: var(--alturaNavBarMobile);
        width: 100%;
        @media (min-width: 992px){
            margin-top: var(--alturaNavBar);
        }
        img{
            width: 100%;
        }
    }
    .containerBeneficioSolucion{
        @media (min-width: 768px){
            display: grid;
            grid-template-columns: 70% 30%;
            margin-bottom: 5rem;
        }
    }
    .contenInfo{
        p{
            font-weight: 600;
            padding-right: 2rem;
            font-size: 0.9rem;
            line-height: 2;
        }
        span{
            font-size: 1.2rem;
            color: var(--colorTerciario);
            font-weight: 900;
        }
        img{
            width: 100%;
            padding: 2rem;
        }
        @media (min-width: 992px) {
            p{
                font-weight: 600;
                font-size: 1.1rem;
                line-height: 2.5;
            }
            img{
                width: 13rem;
                padding: 1rem;
            }
        }
        @media (min-width: 1200px) {
            img{
                width: 16rem;
                padding: 1rem;
            }
        }
        @media (min-width: 1400px) {
            img{
                width: 18rem;
                padding: 1rem;
            }
        }
    }
    .contenBeneficioLogos{
        background-color: var(--colorFondoMain);
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
                width: 10rem;
            }
        @media (min-width: 768px){
            border-radius: 2rem;
            width: 100%;
            img{
                width: 9rem;
            }
        }
    }
    .beneficioSolucion{
        text-align: center;
        margin: 2rem 0rem;
        p{
            font-size: 1.3rem;
            font-weight: 600;
            text-align: center;
            color: #000;
            margin: 0rem;
        }
        @media (min-width: 768px){
            width: 100%;
            padding: 2rem;
            align-items: center;
            display: flex;
            flex-direction: column;
            margin: 0rem;
        }
    }
`