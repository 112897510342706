import styled from "styled-components";
import TableBarton from "../../../component/tables/TableBarton";
import Prices from "../../../component/prices/Prices";
import Documentation from "../../../component/documentation/Documentation";

export default function SolutionPlateasView (){
    return(
        <>
        <StyleSolutionPlateasView>
            <div className="banner">
                <img src="./assets/img/soluciones/icf/bannerIcfx400.jpg" alt="banner Solución de Construcciones ICF"/>
            </div>
            <div className="container">
                <h2>VENTAJAS DEL USO DE BARRAS REFORZADAS DE FIBRA DE VIDRIO EN CONSTRICÓN ICF</h2>
                <div className="containerBeneficioSolucion">
                    <div className="contenInfo">
                        <p>
                        <span>Aislamiento térmico mejorado: </span> Las barras de fibra de vidrio en los sistemas ICF ofrecen un mejor aislamiento térmico en comparación con el hierro de construcción. La baja conductividad térmica de las barras de fibra de vidrio ayuda a reducir las pérdidas de calor y aumentar la eficiencia energética del edificio.
                        </p>
                        <p>
                        <span>Resistencia a la corrosión: </span> Las barras de fibra de vidrio son altamente resistentes a la corrosión, lo que las hace adecuadas para su uso en los sistemas ICF. A diferencia del hierro de construcción, las barras de fibra de vidrio no se corroen ni se oxidan, lo que garantiza una mayor durabilidad y vida útil del sistema.
                        </p>
                        <p>
                        <span>Ligereza: </span> Las barras de fibra de vidrio son mucho más ligeras que el hierro de construcción. Esto facilita su manejo, transporte e instalación durante la construcción del sistema ICF. La ligereza también reduce la carga estructural total del edificio y puede ayudar a acelerar el proceso de construcción.
                        </p>
                        <p>
                        <span>Mayor resistencia al impacto: </span> Las barras de fibra de vidrio en los sistemas ICF ofrecen una mayor resistencia al impacto en comparación con el hierro de construcción. Esto puede ser especialmente beneficioso en áreas propensas a condiciones climáticas extremas o a eventos sísmicos, ya que las barras de fibra de vidrio pueden ayudar a prevenir daños estructurales.
                        </p>
                        <p>
                        <span>Facilidad de instalación y versatilidad de diseño: </span> Las barras de fibra de vidrio son fáciles de instalar en los sistemas ICF y se pueden cortar y dar forma según sea necesario. Esto ofrece una mayor versatilidad en el diseño arquitectónico y permite adaptarse a las necesidades y especificaciones del proyecto.
                        </p>
                        <p>
                        Es importante tener en cuenta que al utilizar barras de fibra de vidrio en los sistemas ICF, se deben seguir las recomendaciones del fabricante y cumplir con las regulaciones y normativas locales aplicables a la construcción.
                        </p>
                        <img src="./assets/img/soluciones/icf/icfx600.jpg" alt="imagen Solucion"/>
                        <img src="./assets/img/soluciones/icf/icf2x600.jpg" alt="imagen Solucion"/>
                        <img src="./assets/img/soluciones/icf/icf3x600.jpg" alt="imagen Solucion"/>
                    </div>
                    <div className="contenBeneficioLogos">
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/termico.svg" alt="Resistente"/>
                            <p>Reduce los efectos de la dilatación térmica en las estructuras</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/corrosion.svg" alt="Anticorrosivo"/>
                            <p>La corrosión no afecta a la estructura haciendola más duradera</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/liviano.svg" alt="MasLiviano"/>
                            <p>Reduce 30% el peso final, haciendo una estructura mucho más liviana</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/resistente.svg" alt="Resistente"/>
                            <p>x3 veces más resistente</p>
                        </div>
                    </div>
                </div>
            </div>
        </StyleSolutionPlateasView>
        <Documentation/>
        <TableBarton/>
        <Prices/>
        </>
    )
}

const StyleSolutionPlateasView = styled.div`
    h2{
        color: var(--colorTerciario);
        font-size: 2.2rem;
        margin: 2rem 0rem;
        @media (min-width: 768px){
            margin: 5rem 0rem;
            text-align: center;
            font-size: 2.8rem;
        }
    }
    .banner{
        margin-top: var(--alturaNavBarMobile);
        width: 100%;
        @media (min-width: 992px){
            margin-top: var(--alturaNavBar);
        }
        img{
            width: 100%;
        }
    }
    .containerBeneficioSolucion{
        @media (min-width: 768px){
            display: grid;
            grid-template-columns: 70% 30%;
            margin-bottom: 5rem;
        }
    }
    .contenInfo{
        p{
            font-weight: 600;
            padding-right: 2rem;
            font-size: 0.9rem;
            line-height: 2;
        }
        span{
            font-size: 1.2rem;
            color: var(--colorTerciario);
            font-weight: 900;
        }
        img{
            width: 100%;
            padding: 2rem;
        }
        @media (min-width: 992px) {
            p{
                font-weight: 600;
                font-size: 1.1rem;
                line-height: 2.5;
            }
            img{
                width: 13rem;
                padding: 1rem;
            }
        }
        @media (min-width: 1200px) {
            img{
                width: 16rem;
                padding: 1rem;
            }
        }
        @media (min-width: 1400px) {
            img{
                width: 18rem;
                padding: 1rem;
            }
        }
    }
    .contenBeneficioLogos{
        background-color: var(--colorFondoMain);
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
                width: 10rem;
            }
        @media (min-width: 768px){
            border-radius: 2rem;
            width: 100%;
            img{
                width: 9rem;
            }
        }
    }
    .beneficioSolucion{
        text-align: center;
        margin: 2rem 0rem;
        p{
            font-size: 1.3rem;
            font-weight: 600;
            text-align: center;
            color: #000;
            margin: 0rem;
        }
        @media (min-width: 768px){
            width: 100%;
            padding: 2rem;
            align-items: center;
            display: flex;
            flex-direction: column;
            margin: 0rem;
        }
    }
`