import { Component } from "react";
import styled from "styled-components";
import BartonService from "../../../services/BartonService";
import ModalNewCategory from "../../../component/modal/ModalNewCategory";
import ModalEditCategory from "../../../component/modal/ModalEditCategory";
import Loader from "../../../component/loader/Loader"

export default class CategorysView extends Component {
    constructor(props){
        super (props);
        this.state={
            categorys: [],
            form:{
                name:'',
                description:'',
            },
            isLoading: true,
        }
    }

    componentDidMount(){
        this.searchCategorysAll()
    }
    
    async searchCategorysAll () {
        const response = await BartonService.searchCategorysAll()
        this.setState({
            categorys: response.data,
            isLoading: false,
        })
    }

    handleSubmit = async (name, description) => {
        this.setState({ isLoading: true });
        const response = await BartonService.categoryNew(name, description);
        console.log(response)
        if (response.status === 200) { // Verifica si la respuesta es exitosa
            console.log('cargo correcto categoria nueva') // Cambia el estado de modalNew a false para cerrar la ventana modal
        }
        this.searchCategorysAll()
        this.setState({ isLoading: false });
    }

    deleteCategory = async (idCategory) => {
        this.setState({ isLoading: true });
        const response = await BartonService.deleteCategory(idCategory)
        console.log (response);
        this.searchCategorysAll();
        this.setState({ isLoading: false });
    }

    editCategory = async (idCategory, name, description) => {
        this.setState({ 
            isLoading: true,
            categorys: [],
        });
        console.log(idCategory)
        const response = await BartonService.categoryEdit(idCategory, name, description)
        console.log (response);
        this.setState({ isLoading: false });
        this.searchCategorysAll();
    }

    render(){
        const { isLoading } = this.state;
        return(
            <StyleCategorysView>
                {/* muestra el componente Loader si isLoading es true */}
                {isLoading ? ( <Loader/> ) : (
                <>
                    <table className="table tableCategory">
                        <thead>
                            <tr>
                                <th className="styleRowTitle">Nombre</th>
                                <th className="styleRowTitle">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.state.categorys.map(category => {
                                return(
                                    <tr key={category.id}>
                                        <td><p>{category.name}</p></td>
                                        <td className="styleRowsButton">
                                        <ModalEditCategory
                                            getContentEditCategory={this.editCategory}
                                            idboton='#modalEditCategory'
                                            idmodal='modalEditCategory'
                                            title='Editar Categoria'
                                            nameButon='Editar'
                                            idCategory={category.id}
                                            name={category.name}
                                            description={category.description}
                                        />                     
                                        <button onClick={() => this.deleteCategory(category.id)} className="btn btn-danger">eliminar</button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="styleRowNewCategory">
                        <ModalNewCategory getContentNewCategory={this.handleSubmit} 
                            idboton= '#modalNewCategory' 
                            idmodal='modalNewCategory' 
                            title='Agregar Categoria Nueva' 
                            nameButon='Agregar Categoria Nueva'/>
                    </div>
                </>)}
            </StyleCategorysView>
        )
    }
}

const StyleCategorysView = styled.section`
    .tableCategory{
        width: 35rem;
    }
    .styleRowTitle{
        text-align: center;
    }
    .styleRowsButton{
        text-align: end;
    }
    .styleRowNewCategory{
        text-align: center;
    }
`