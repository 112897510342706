import styled from "styled-components";

export default function TableComparation(){
    return(
        <StyleTableComparation>
            <div className="container" style={{marginTop: "4rem"}}>
                <h3 style={{marginTop: "4rem", textAlign: "center"}}>COMPARACIÓN DE PESO DE BARTON 1000 VS ACERO ADN 420</h3>

                <h5 style={{marginTop: "2rem"}}>Barra 5</h5>
                <div className="progress-stacked styleBar" style={{width: "100%"}}>
                    <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{width: "19%"}}>
                        <div className="progress-bar bg-success"><p>BARTON: 0,035 kg</p></div>
                    </div>
                </div>
                <div className="progress-stacked styleBar" style={{width: "100%", marginTop:"0.5rem"}}>
                    <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{width: "60%"}}>
                        <div className="progress-bar bg-secondary"><p>ADN 420: 0,108 kg</p></div>
                    </div>
                </div>

                <h5 style={{marginTop: "2rem"}}>Barra 6</h5>
                <div className="progress-stacked styleBar" style={{width: "100%"}}>
                    <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{width: "23%"}}>
                        <div className="progress-bar bg-success"><p>BARTON: 0,08 kg</p></div>
                    </div>
                </div>
                <div className="progress-stacked styleBar" style={{width: "100%", marginTop:"0.5rem"}}>
                    <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{width: "65%"}}>
                        <div className="progress-bar bg-secondary"><p>ADN 420: 0,222 kg</p></div>
                    </div>
                </div>

                <h5 style={{marginTop: "1rem"}}>Barra 8</h5>
                <div className="progress-stacked styleBar" style={{width: "100%"}}>
                    <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{width: "21%"}}>
                        <div className="progress-bar bg-success"><p>BARTON: 0,12 kg</p></div>
                    </div>
                </div>
                <div className="progress-stacked styleBar" style={{width: "100%", marginTop:"0.5rem"}}>
                    <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{width: "70%"}}>
                        <div className="progress-bar bg-secondary"><p>ADN 420: 0,395 kg</p></div>
                    </div>
                </div>

                <h5 style={{marginTop: "1rem"}}>Barra 10</h5>
                <div className="progress-stacked styleBar" style={{width: "100%"}}>
                    <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{width: "18%"}}>
                        <div className="progress-bar bg-success"><p>BARTON: 0,15 kg</p></div>
                    </div>
                </div>
                <div className="progress-stacked styleBar" style={{width: "100%", marginTop:"0.5rem"}}>
                    <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{width: "75%"}}>
                        <div className="progress-bar bg-secondary"><p>ADN 420: 0,617 kg</p></div>
                    </div>
                </div>

                <h5 style={{marginTop: "1rem"}}>Barra 12</h5>
                <div className="progress-stacked styleBar" style={{width: "100%"}}>
                    <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{width: "20%"}}>
                        <div className="progress-bar bg-success"><p>BARTON: 0,23 kg</p></div>
                    </div>
                </div>
                <div className="progress-stacked styleBar" style={{width: "100%", marginTop:"0.5rem"}}>
                    <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{width: "80%"}}>
                        <div className="progress-bar bg-secondary"><p>ADN 420: 0,888 kg</p></div>
                    </div>
                </div>

                <h5 style={{marginTop: "1rem"}}>Barra 16</h5>
                <div className="progress-stacked styleBar" style={{width: "100%"}}>
                    <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{width: "22%"}}>
                        <div className="progress-bar bg-success"><p>BARTON: 0,41 kg</p></div>
                    </div>
                </div>
                <div className="progress-stacked styleBar" style={{width: "100%", marginTop:"0.5rem"}}>
                    <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{width: "85%"}}>
                        <div className="progress-bar bg-secondary"><p>ADN 420: 1,58 kg</p></div>
                    </div>
                </div>

                <h5 style={{marginTop: "1rem"}}>Barra 20</h5>
                <div className="progress-stacked styleBar" style={{width: "100%"}}>
                    <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{width: "21.5%"}}>
                        <div className="progress-bar bg-success"><p>BARTON: 0,59 kg</p></div>
                    </div>
                </div>
                <div className="progress-stacked styleBar" style={{width: "100%", marginTop:"0.5rem"}}>
                    <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{width: "90%"}}>
                        <div className="progress-bar bg-secondary"><p>ADN 420: 2,47 kg</p></div>
                    </div>
                </div>

                <h5 style={{marginTop: "1rem"}}>Barra 25</h5>
                <div className="progress-stacked styleBar" style={{width: "100%"}}>
                    <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{width: "26%"}}>
                        <div className="progress-bar bg-success"><p>BARTON: 1,06 kg</p></div>
                    </div>
                </div>
                <div className="progress-stacked styleBar" style={{width: "100%", marginTop:"0.5rem"}}>
                    <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{width: "95%"}}>
                        <div className="progress-bar bg-secondary"><p>ADN 420: 3,85 kg</p></div>
                    </div>
                </div>

                {/* <h5 style={{marginTop: "1rem"}}>Barra 32</h5>
                <div className="progress-stacked styleBar" style={{width: "100%"}}>
                    <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{width: "16.1%"}}>
                        <div className="progress-bar bg-success"><p>BARTON: 1,02 kg</p></div>
                    </div>
                </div>
                <div className="progress-stacked styleBar" style={{width: "100%", marginTop:"0.5rem"}}>
                    <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{width: "100%"}}>
                        <div className="progress-bar bg-secondary"><p>ADN 420: 6,310 kg</p></div>
                    </div>
                </div> */}
            </div>
        </StyleTableComparation>
    )
}

const StyleTableComparation = styled.div`
    //nuevo estilo a dejar
    .conterProgress{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin-top: 1rem;
    }
    .conterProgress h5 {
        margin-bottom: 0rem;
    }
    .styleBar{
        height: 30px;
        border-radius: 0px;
        @media (min-width: 768px){
            height: 40px;
        }
    }
    .progress{
        height: 100%;
    }
    .progress-bar p {
        font-weight: 900;
        margin-bottom: 0rem;
        color: #fff;
        font-size: 9px;
        @media (min-width: 768px){
            font-size: 16px;
        }
    }
`