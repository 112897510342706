import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Link} from 'react-router-dom';
import App from './App';
import ScrollToTop from './component/scrollToTop/ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <ScrollToTop />
    <Link target="_blank" to={'https://wa.me/+5491121836522?text=Hola%20como%20estas?%20deseo%20tener%20más%20información%20sobre%20las%20barras%20Barton.%20Gracias!'}>
      <div style={{
        backgroundImage: `url('/assets/img/whatsapp.svg')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: 'fit-content',
        position: 'fixed',
        bottom: '10px',
        right: '20px',
        padding: '30px',
        zIndex: '999'
        }}>
      </div>
    </Link>
    <Link target="_blank" to={'https://www.instagram.com/bartonrebar/'}>
      <div style={{
          backgroundImage: `url('/assets/img/instagram.svg')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: 'fit-content',
          position: 'fixed',
          bottom: '70px',
          right: '20px',
          padding: '30px',
          zIndex: '998'
          }}>
      </div>
    </Link>
    <Link target="_blank" to={'https://www.youtube.com/@bartonrebarsa'}>
      <div style={{
        backgroundImage: `url('/assets/img/youtube.svg')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: 'fit-content',
        position: 'fixed',
        bottom: '130px',
        right: '20px',
        padding: '30px',
        zIndex: '997'
      }}>
      </div>
    </Link>
    <App />
  </BrowserRouter>
);