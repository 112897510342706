import styled from "styled-components";
import TableBarton from "../../../component/tables/TableBarton";
import Prices from "../../../component/prices/Prices";
import Documentation from "../../../component/documentation/Documentation";

export default function SolutionPlateasView (){
    return(
        <>
        <StyleSolutionPlateasView>
            <div className="banner">
                <img src="./assets/img/soluciones/plateas/bannerPlateasx400.jpg" alt="banner Solución de Plateas"/>
            </div>
            <div className="container">
                <h2>VENTAJAS DEL USO DE BARRAS REFORZADAS DE FIBRA DE VIDRIO EN OBRAS DE Plateas y Veredas</h2>
                <div className="containerBeneficioSolucion">
                    <div className="contenInfo">
                        <p>
                        <span>Resistencia a la corrosión: </span> Las barras de fibra de vidrio son altamente resistentes a la corrosión, lo que las hace ideales para plateas y veredas que están expuestas a la intemperie y al contacto con el suelo, agua u otros elementos corrosivos. No se corroen ni se oxidan, lo que garantiza una mayor durabilidad y vida útil de la estructura.
                        </p>
                        <p>
                        <span>Ligereza: </span> Las barras de fibra de vidrio son mucho más ligeras que el hierro de construcción. Esto facilita su manipulación, transporte e instalación durante la construcción de la platea o vereda. Además, su ligereza puede reducir la carga muerta en la estructura, lo que puede ser beneficioso para proyectos de gran envergadura.
                        </p>
                        <p>
                        <span>Resistencia mecánica: </span> Las barras de fibra de vidrio tienen una alta resistencia mecánica, lo que les permite soportar cargas y tensiones sin deformarse ni romperse. Son capaces de resistir las fuerzas asociadas con el tráfico peatonal y ligero, así como los movimientos del suelo, lo que las hace adecuadas para plateas y veredas.
                        </p>
                        <p>
                        <span>Baja conductividad térmica: </span> Las barras de fibra de vidrio tienen una baja conductividad térmica en comparación con el hierro de construcción. Esto significa que no se calientan o enfrían rápidamente en respuesta a cambios de temperatura, lo que ayuda a reducir los efectos de la dilatación térmica en las estructuras y evita la formación de grietas.
                        </p>
                        <p>
                        <span>Facilidad de instalación: </span> Las barras de fibra de vidrio son flexibles y se pueden cortar y moldear fácilmente según las necesidades del proyecto. Esto simplifica el proceso de instalación y permite una mayor adaptabilidad en términos de diseño y forma de la platea o vereda.
                        </p>
                        <p>
                        Es importante tener en cuenta que al utilizar barras de fibra de vidrio en la construcción de plateas y veredas, se deben considerar otros factores, como el diseño estructural, las cargas de servicio y las regulaciones y normativas locales aplicables.
                        </p>
                        <img src="./assets/img/soluciones/plateas/Plateasx600.jpg" alt="imagen Solucion"/>
                        <img src="./assets/img/soluciones/plateas/Plateas2x600.jpg" alt="imagen Solucion"/>
                        <img src="./assets/img/soluciones/plateas/Plateas3x600.jpg" alt="imagen Solucion"/>
                    </div>
                    <div className="contenBeneficioLogos">
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/corrosion.svg" alt="Anticorrosivo"/>
                            <p>La corrosión no afecta a la estructura haciendola más duradera</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/liviano.svg" alt="MasLiviano"/>
                            <p>Reduce 30% el peso final, haciendo una estructura mucho más liviana</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/resistente.svg" alt="Resistente"/>
                            <p>x3 veces más resistente</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/termico.svg" alt="Resistente"/>
                            <p>Reduce los efectos de la dilatación térmica en las estructuras</p>
                        </div>
                    </div>
                </div>
            </div>
        </StyleSolutionPlateasView>
        <Documentation/>
        <TableBarton/>
        <Prices/>
        </>
    )
}

const StyleSolutionPlateasView = styled.div`
   h2{
       color: var(--colorTerciario);
        font-size: 2.2rem;
        margin: 2rem 0rem;
        @media (min-width: 768px){
            margin: 5rem 0rem;
            text-align: center;
            font-size: 2.8rem;
        }
    }
    .banner{
        margin-top: var(--alturaNavBarMobile);
        width: 100%;
        @media (min-width: 992px){
            margin-top: var(--alturaNavBar);
        }
        img{
            width: 100%;
        }
    }
    .containerBeneficioSolucion{
        @media (min-width: 768px){
            display: grid;
            grid-template-columns: 70% 30%;
            margin-bottom: 5rem;
        }
    }
    .contenInfo{
        p{
            font-weight: 600;
            padding-right: 2rem;
            font-size: 0.9rem;
            line-height: 2;
        }
        span{
            font-size: 1.2rem;
            color: var(--colorTerciario);
            font-weight: 900;
        }
        img{
            width: 100%;
            padding: 2rem;
        }
        @media (min-width: 992px) {
            p{
                font-weight: 600;
                font-size: 1.1rem;
                line-height: 2.5;
            }
            img{
                width: 13rem;
                padding: 1rem;
            }
        }
        @media (min-width: 1200px) {
            img{
                width: 16rem;
                padding: 1rem;
            }
        }
        @media (min-width: 1400px) {
            img{
                width: 18rem;
                padding: 1rem;
            }
        }
    }
    .contenBeneficioLogos{
        background-color: var(--colorFondoMain);
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
                width: 10rem;
            }
        @media (min-width: 768px){
            border-radius: 2rem;
            width: 100%;
            img{
                width: 9rem;
            }
        }
    }
    .beneficioSolucion{
        text-align: center;
        margin: 2rem 0rem;
        p{
            font-size: 1.3rem;
            font-weight: 600;
            text-align: center;
            color: #000;
            margin: 0rem;
        }
        @media (min-width: 768px){
            width: 100%;
            padding: 2rem;
            align-items: center;
            display: flex;
            flex-direction: column;
            margin: 0rem;
        }
    }
`