import styled from "styled-components";
import TableBarton from "../../../component/tables/TableBarton";
import Prices from "../../../component/prices/Prices";
import Documentation from "../../../component/documentation/Documentation";

export default function SolutionPiletasView (){
    return(
        <>
        <StyleSolutionPiletasView>
            <div className="banner">
                <img src="./assets/img/soluciones/piletas/bannerPiletasx400.jpg" alt="banner Solución de Piletas"/>
            </div>
            <div className="container">
                <h2>VENTAJAS DEL USO DE BARRAS REFORZADAS DE FIBRA DE VIDRIO EN OBRAS DE Piletas</h2>
                <div className="containerBeneficioSolucion">
                    <div className="contenInfo">
                        <p>
                        <span>Resistencia a la corrosión: </span> Las barras de fibra de vidrio son altamente resistentes a la corrosión, lo que las hace ideales para su uso en piletas que están en contacto constante con agua y productos químicos, como cloro. A diferencia del hierro de construcción, no se corroen, lo que garantiza una mayor durabilidad y vida útil de la piscina.
                        </p>
                        <p>
                        <span>Flexibilidad de diseño: </span> Las barras de fibra de vidrio se pueden moldear y fabricar en diferentes formas y tamaños según los requisitos de diseño de la piscina. Esto ofrece una mayor flexibilidad en términos de personalización y permite la creación de formas curvas y diseños innovadores.
                        </p>
                        <p>
                        <span>Aislamiento térmico: </span> Las barras de fibra de vidrio tienen una baja conductividad térmica, lo que significa que no transmiten el calor rápidamente. Esto puede ayudar a mantener la temperatura del agua en la piscina y reducir las pérdidas de calor, lo que puede resultar en un ahorro de energía en términos de calefacción y uso de sistemas de climatización.
                        </p>
                        <p>
                        <span>Ligereza: </span> Las barras de fibra de vidrio son mucho más ligeras que el hierro de construcción, lo que facilita su manipulación, transporte e instalación durante la construcción de la piscina. Esto puede ahorrar tiempo y costos de mano de obra durante el proceso de construcción.
                        </p>
                        <p>
                        <span>Durabilidad y resistencia:  </span>Las barras de fibra de vidrio son extremadamente duraderas y resistentes. Son capaces de soportar cargas y tensiones sin deformarse ni romperse, lo que las convierte en una opción ideal para la construcción de piscinas que estarán sometidas a presión hidrostática constante y posibles movimientos del suelo.
                        </p>
                        <p>
                        Es importante tener en cuenta que al utilizar barras de fibra de vidrio en la construcción de una piscina, es necesario considerar otros factores, como la impermeabilización adecuada, el diseño estructural y las regulaciones locales aplicables a las piscinas.
                        </p>
                        <img src="./assets/img/soluciones/piletas/Piletasx600.jpg" alt="imagen Solucion"/>
                        <img src="./assets/img/soluciones/piletas/Piletas2x600.jpg" alt="imagen Solucion"/>
                        <img src="./assets/img/soluciones/piletas/Piletas3x600.jpg" alt="imagen Solucion"/>
                    </div>
                    <div className="contenBeneficioLogos">
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/corrosion.svg" alt="Anticorrosivo"/>
                            <p>La corrosión no afecta a la estructura haciendola más duradera</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/termico.svg" alt="Resistente"/>
                            <p>Reduce los efectos de la dilatación térmica en las estructuras</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/liviano.svg" alt="MasLiviano"/>
                            <p>Reduce 30% el peso final, haciendo una estructura mucho más liviana</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/resistente.svg" alt="Resistente"/>
                            <p>x3 veces más resistente</p>
                        </div>
                    </div>
                </div>
            </div>
        </StyleSolutionPiletasView>
        <Documentation/>
        <TableBarton/>
        <Prices/>
        </>
    )
}

const StyleSolutionPiletasView = styled.div`
    h2{
        color: var(--colorTerciario);
        font-size: 2.2rem;
        margin: 2rem 0rem;
        @media (min-width: 768px){
            margin: 5rem 0rem;
            text-align: center;
            font-size: 2.8rem;
        }
    }
    .banner{
        margin-top: var(--alturaNavBarMobile);
        width: 100%;
        @media (min-width: 992px){
            margin-top: var(--alturaNavBar);
        }
        img{
            width: 100%;
        }
    }
    .containerBeneficioSolucion{
        @media (min-width: 768px){
            display: grid;
            grid-template-columns: 70% 30%;
            margin-bottom: 5rem;
        }
    }
    .contenInfo{
        p{
            font-weight: 600;
            padding-right: 2rem;
            font-size: 0.9rem;
            line-height: 2;
        }
        span{
            font-size: 1.2rem;
            color: var(--colorTerciario);
            font-weight: 900;
        }
        img{
            width: 100%;
            padding: 2rem;
        }
        @media (min-width: 992px) {
            p{
                font-weight: 600;
                font-size: 1.1rem;
                line-height: 2.5;
            }
            img{
                width: 13rem;
                padding: 1rem;
            }
        }
        @media (min-width: 1200px) {
            img{
                width: 16rem;
                padding: 1rem;
            }
        }
        @media (min-width: 1400px) {
            img{
                width: 18rem;
                padding: 1rem;
            }
        }
    }
    .contenBeneficioLogos{
        background-color: var(--colorFondoMain);
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
                width: 10rem;
            }
        @media (min-width: 768px){
            border-radius: 2rem;
            width: 100%;
            img{
                width: 9rem;
            }
        }
    }
    .beneficioSolucion{
        text-align: center;
        margin: 2rem 0rem;
        p{
            font-size: 1.3rem;
            font-weight: 600;
            text-align: center;
            color: #000;
            margin: 0rem;
        }
        @media (min-width: 768px){
            width: 100%;
            padding: 2rem;
            align-items: center;
            display: flex;
            flex-direction: column;
            margin: 0rem;
        }
    }
`