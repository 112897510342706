import styled from "styled-components";
import TableBarton from "../../../component/tables/TableBarton";
import Prices from "../../../component/prices/Prices";
import Documentation from "../../../component/documentation/Documentation";

export default function SolutionObrasElectricasView (){
    return(
        <>
        <StyleSolutionObrasElectricasView>
            <div className="banner">
                <img src="./assets/img/soluciones/electricas/bannerElectricasx400.jpg" alt="banner Solución de Obras Eléctricas"/>
            </div>
            <div className="container">
                <h2>VENTAJAS DEL USO DE BARRAS REFORZADAS DE FIBRA DE VIDRIO EN OBRAS Eléctricas</h2>
                <div className="containerBeneficioSolucion">
                    <div className="contenInfo">
                        <p>
                        <span>Aislamiento eléctrico: </span> Las barras de fibra de vidrio tienen una alta resistencia dieléctrica, lo que las hace excelentes para su uso en obras eléctricas y de comunicaciones. Proporcionan un aislamiento eficaz frente a corrientes eléctricas, lo que minimiza el riesgo de cortocircuitos y descargas eléctricas.
                        </p>
                        <p>
                        <span>Resistencia a la corrosión: </span> Las barras de fibra de vidrio son altamente resistentes a la corrosión y no se ven afectadas por la humedad o la exposición a productos químicos. Esto las hace ideales para entornos donde las obras eléctricas y de comunicaciones están expuestas a la intemperie o a ambientes corrosivos, como áreas costeras o instalaciones industriales.
                        </p>
                        <p>
                        <span>Ligereza: </span> Las barras de fibra de vidrio son considerablemente más ligeras que el hierro de construcción. Esto facilita su manipulación, transporte e instalación en el lugar de trabajo, lo que puede agilizar los proyectos y reducir los costos de mano de obra.
                        </p>
                        <p>
                        <span>Durabilidad: </span> Las barras de fibra de vidrio son resistentes a impactos y vibraciones, lo que las hace adecuadas para obras eléctricas y de comunicaciones que puedan estar expuestas a condiciones adversas. Son capaces de soportar cargas mecánicas y mantener su integridad estructural a lo largo del tiempo.
                        </p>
                        <p>
                        <span>Baja interferencia electromagnética: </span> Las barras de fibra de vidrio tienen una baja conductividad eléctrica, lo que reduce la interferencia electromagnética. Esto es especialmente importante en obras de comunicaciones, donde se busca minimizar la pérdida de señal y mejorar la calidad de transmisión.
                        </p>
                        <p>
                        Es importante tener en cuenta que, si bien las barras de fibra de vidrio ofrecen estas ventajas, se deben considerar los requisitos específicos de cada proyecto, así como las normativas y regulaciones aplicables en el ámbito eléctrico y de comunicaciones.
                        </p>
                        <img src="./assets/img/soluciones/electricas/Electricasx600.jpg" alt="imagen Solucion"/>
                        <img src="./assets/img/soluciones/electricas/Electricas2x600.jpg" alt="imagen Solucion"/>
                        <img src="./assets/img/soluciones/electricas/Electricas3x600.jpg" alt="imagen Solucion"/>
                    </div>
                    <div className="contenBeneficioLogos">
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/dielectrico.svg" alt="Dieléctrico"/>
                            <p>Proporcionan un aislamiento eléctrico efectivo y minimizan el riesgo de descargas eléctricas</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/corrosion.svg" alt="Anticorrosivo"/>
                            <p>La corrosión no afecta a la estructura haciendola más duradera</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/liviano.svg" alt="MasLiviano"/>
                            <p>Reduce 30% el peso final, haciendo una estructura mucho más liviana</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/resistente.svg" alt="Resistente"/>
                            <p>x3 veces más resistente</p>
                        </div>
                    </div>
                </div>
            </div>
        </StyleSolutionObrasElectricasView>
        <Documentation/>
        <TableBarton/>
        <Prices/>
        </>
    )
}

const StyleSolutionObrasElectricasView = styled.div`
    h2{
        color: var(--colorTerciario);
        font-size: 2.2rem;
        margin: 2rem 0rem;
        @media (min-width: 768px){
            margin: 5rem 0rem;
            text-align: center;
            font-size: 2.8rem;
        }
    }
    .banner{
        margin-top: var(--alturaNavBarMobile);
        width: 100%;
        @media (min-width: 992px){
            margin-top: var(--alturaNavBar);
        }
        img{
            width: 100%;
        }
    }
    .containerBeneficioSolucion{
        @media (min-width: 768px){
            display: grid;
            grid-template-columns: 70% 30%;
            margin-bottom: 5rem;
        }
    }
    .contenInfo{
        p{
            font-weight: 600;
            padding-right: 2rem;
            font-size: 0.9rem;
            line-height: 2;
        }
        span{
            font-size: 1.2rem;
            color: var(--colorTerciario);
            font-weight: 900;
        }
        img{
            width: 100%;
            padding: 2rem;
        }
        @media (min-width: 992px) {
            p{
                font-weight: 600;
                font-size: 1.1rem;
                line-height: 2.5;
            }
            img{
                width: 13rem;
                padding: 1rem;
            }
        }
        @media (min-width: 1200px) {
            img{
                width: 16rem;
                padding: 1rem;
            }
        }
        @media (min-width: 1400px) {
            img{
                width: 18rem;
                padding: 1rem;
            }
        }
    }
    .contenBeneficioLogos{
        background-color: var(--colorFondoMain);
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
                width: 10rem;
            }
        @media (min-width: 768px){
            border-radius: 2rem;
            width: 100%;
            img{
                width: 9rem;
            }
        }
    }
    .beneficioSolucion{
        text-align: center;
        margin: 2rem 0rem;
        p{
            font-size: 1.3rem;
            font-weight: 600;
            text-align: center;
            color: #000;
            margin: 0rem;
        }
        @media (min-width: 768px){
            width: 100%;
            padding: 2rem;
            align-items: center;
            display: flex;
            flex-direction: column;
            margin: 0rem;
        }
    }
`