import BenefitsGrup from '../../component/benefit/BenefitGrup';
import VideoDiv from '../../component/videoHome/VideoDiv';
import BenefitsFlat from '../../component/benefitsFlat/BenefitsFlat';
import TagsGrup from '../../component/tags/TagsGrup';
import DivisionImg from '../../component/divisionImg/DivisionImg';
import Documentation from '../../component/documentation/Documentation';
import TableBarton from '../../component/tables/TableBarton';
import ComparisonGroup from '../../component/comparison/ComparisonGroup';
import VideoHomeSimple from '../../component/videoHome/VideoHomeSimple';
import TableComparation from '../../component/tables/TableComparation';
import Prices from '../../component/prices/Prices';
import Calculating from '../../component/calculating/Calculating';

function HomeView() {
    return (
        <section>
            <VideoHomeSimple/>
            <div id="beneficios">
                <BenefitsGrup listBenefits={listBenefits}/>
            </div>
            <VideoDiv/>
            <div id='pisos'>
                <BenefitsFlat/>
            </div>
            <div id='aplicaciones'></div>
                <TagsGrup listTag={listTag}/>
            <DivisionImg/>
            <div id='documentacion' style={{marginBottom:"5rem"}}>
                <Documentation/>
            </div>
            <div style={{backgroundColor: "var(--colorFondoMain)", paddingTop:"0.1rem", paddingBottom:"5rem"}}>
                <TableComparation/>
            </div>
            <TableBarton/>
            <ComparisonGroup listComparison={listComparison}/>
            <div >
                <Prices/>
            </div>
        </section>
    )
};

const listTag = [
    { id: 1, title: "Plateas y Veredas", link:'/solucionPlateas', img:'./assets/img/soluciones/platea.jpg'},
    { id: 2, title: "Pisos Industriales", link:'/solucionPisosIndustriales', img:'./assets/img/soluciones/pisos.jpg'},
    { id: 3, title: "Piletas", link:'/solucionPiletas', img:'./assets/img/soluciones/piletas.jpg'},
    { id: 4, title: "Premoldeados", link:'/solucionPremoldeados', img:'./assets/img/soluciones/premoldeados.jpg'},
    { id: 5, title: "Obras Eléctricas", link:'/solucionElectrica', img:'./assets/img/soluciones/electricas.jpg'},
    { id: 6, title: "Infraestructura", link:'/solucionInfraestructuras', img:'./assets/img/soluciones/infraestructuras/infraestructuras.jpg'},
    { id: 7, title: "Construcciones ICF", link:'/solucionIcf', img:'./assets/img/soluciones/icf.jpg'},
    { id: 8, title: "Obras Hidroeléctricas", link:'/SolucionHidroelectricas', img:'./assets/img/soluciones/hidroelectrica.jpg'},
    { id: 9, title: "Muelles y Puertos", link:'/solucionMuelles', img:'./assets/img/soluciones/muelles.jpg'},
    { id: 10, title: "Plantas Industriales", link:'solucionPlantasIndustriales', img:'./assets/img/soluciones/industrias.jpg'},
];

const listBenefits = [
    { id: 1, title:'4 VECES MÁS LIVIANO', logo:'./assets/img/benefits/liviano2.svg', img:'./assets/img/benefits/liviano/liviano800.jpg', link: '/beneficioLiviano', info:'Las barras de refuerzo son resistentes a la corrosión, a diferencia de las fabricadas con materiales tradicionales, que eventualmente se oxidaran y agrietarán el concreto.'},
    { id: 2, title:'3 VECES MÁS RESISTENTE', logo:'./assets/img/benefits/resistente2.svg', img:'./assets/img/benefits/resistente/resistente800.jpg', link: '/beneficioResistente', info:'Las barras de refuerzo son resistentes a la corrosión, a diferencia de las fabricadas con materiales tradicionales, que eventualmente se oxidaran y agrietarán el concreto.'},
    { id: 3, title:'FÁCILMENTE TRANSPORTABLE', logo:'./assets/img/benefits/flexible.svg', img:'./assets/img/benefits/transportable/transportable800.jpg', link: '/beneficioTransportable', info:'Las barras de refuerzo son resistentes a la corrosión, a diferencia de las fabricadas con materiales tradicionales, que eventualmente se oxidaran y agrietarán el concreto.'},
    { id: 4, title:'ANTICORROSIVO y DIELÉCTRICO', logo:'./assets/img/benefits/no.svg', img:'./assets/img/benefits/corrosion/anticorrosion800.jpg', link:'/beneficioAnticorrosion', info:'Las barras de refuerzo son resistentes a la corrosión, a diferencia de las fabricadas con materiales tradicionales, que eventualmente se oxidaran y agrietarán el concreto.'},
];

const listComparison= [
    {id: 1, title:'Prueba de Tracción', titleVideo:'Prueba de tracción', video:'https://player.vimeo.com/video/821118537?controls=0?&autopause=false&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1', info:'Las barras Barton 1000, son testeadas en nuestro laboratorio con equipamiento certificado por INTI, numero de certificado: IF-2022-118714672-APN-SOMCEI*INTI'},
    //{id: 2, title:'Comparación2', video:'https://player.vimeo.com/video/821118537?controls=0?&autopause=false&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1', info:'Las barras de refuerzo son resistentes a la corrosión, a diferencia de las fabricadas con materiales tradicionales, que eventualmente se oxidaran y agrietarán el concreto.'},
    // {id: 3, title:'Comparación3', video:'./assets/img/videos/video.jpg', info:'Las barras de refuerzo son resistentes a la corrosión, a diferencia de las fabricadas con materiales tradicionales, que eventualmente se oxidaran y agrietarán el concreto.'},
    // {id: 4, title:'Comparación4', video:'./assets/img/videos/video.jpg', info:'Las barras de refuerzo son resistentes a la corrosión, a diferencia de las fabricadas con materiales tradicionales, que eventualmente se oxidaran y agrietarán el concreto.'}
];

export default HomeView;