import styled from "styled-components";

export default function Comparison(props){
    const id = props.comparisonInfo.id;

    return(
        <StyleComparison id={id} style={{display: 'flex', justifyContent: 'center'}}>
            <div className="row containerComparison">
                <div className="col-xl-8" style={{display: 'flex', justifyContent: 'center'}}>
                    <div className="containerVideo">
                            <iframe className="video"
                                    title={props.comparisonInfo.titleVideo}
                                    src={props.comparisonInfo.video}  
                                    frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen>
                            </iframe>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </div>
                </div>
                <div className="col-xl-4">
                    <div className="textComparison">
                        <h2 className="titleComparison">{props.comparisonInfo.title}</h2>
                        <p>{props.comparisonInfo.info}</p>
                    </div>
                </div>
                <div id='precios'></div>
            </div>
        </StyleComparison>
    )
}

const StyleComparison = styled.div`
    .containerComparison{
        display: grid;
        border-radius: 10px;
        grid-template-columns: repeat(1, 1fr);
        background-color: #fff;
        margin-top: 2rem;
        width: 100%;
        height: 100%;
        @media (min-width: 1140px){
            display: flex;
            flex-direction: ${({ id }) => (id % 2 === 0 ? "row" : "row-reverse")};
        }
    }
    .containerVideo{
        width: 100%;
        height: 100%;
        margin-top: 1rem;
        
        @media (min-width: 768px){
            max-width: 800px;
            height: 450px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0px;
            padding: 2rem 0rem;
        }
    }
    .video{
        width: 100%;
        height: 100%;    
        @media (min-width: 768px){
            margin: 0px;
            width: 100%;
            height: 100%;
        }
    }
    .textComparison{
        padding: 2rem;
        line-height: 2.2;
        @media (min-width: 1140px){
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 2rem 0rem;
        }
    }
    .textComparison p{
        margin-top: 2rem;
    }
`