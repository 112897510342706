import styled from "styled-components";
import TableBarton from "../../../component/tables/TableBarton";
import Prices from "../../../component/prices/Prices";
import Documentation from "../../../component/documentation/Documentation";

export default function SolutionPremoldeadoView (){
    return(
        <>
        <StyleSolutionPremoldeadoView>
            <div className="banner">
                <img src="./assets/img/soluciones/premoldeado/bannerPremoldeadox400.jpg" alt="banner Solución de Premoldeado"/>
            </div>
            <div className="container">
                <h2>VENTAJAS DEL USO DE BARRAS REFORZADAS DE FIBRA DE VIDRIO EN OBRAS DE Premoldeado</h2>
                <div className="containerBeneficioSolucion">
                    <div className="contenInfo">
                        <p>
                            <span>Ligereza: </span> Las barras de fibra de vidrio son considerablemente más ligeras que el hierro de construcción. Esto facilita el manejo, transporte e instalación de los premoldeados durante el proceso de construcción. Además, su ligereza puede reducir la carga estructural total, lo que puede ser beneficioso en términos de diseño y costos.
                        </p>
                        <p>
                            <span>Resistencia a la corrosión: </span> Las barras de fibra de vidrio son altamente resistentes a la corrosión, lo que las hace ideales para premoldeados expuestos a la intemperie o en ambientes corrosivos. No se corroen ni se oxidan, lo que garantiza una mayor durabilidad y vida útil de los elementos prefabricados.
                        </p>
                        <p>
                            <span>Durabilidad: </span> Las barras de fibra de vidrio son extremadamente duraderas y tienen una excelente resistencia mecánica. Son capaces de soportar cargas y tensiones sin deformarse ni romperse, lo que asegura la integridad estructural de los premoldeados a lo largo del tiempo.
                        </p>
                        <p>
                            <span>Flexibilidad de diseño: </span> Las barras de fibra de vidrio pueden ser moldeadas y fabricadas en una variedad de formas y tamaños según los requisitos de diseño de los premoldeados. Esto ofrece una mayor flexibilidad en términos de personalización y permite la creación de diseños arquitectónicos más complejos y estéticamente atractivos.
                        </p>
                        <p>
                            <span>Aislamiento térmico: </span>Las barras de fibra de vidrio tienen una baja conductividad térmica, lo que ayuda a proporcionar un buen aislamiento térmico en los premoldeados. Esto puede ser especialmente beneficioso en elementos como paredes prefabricadas, donde se busca reducir la transferencia de calor y mejorar la eficiencia energética de los edificios.
                        </p>
                        <p>
                        Es importante tener en cuenta que, si bien las barras de fibra de vidrio ofrecen estas ventajas, se deben considerar los requisitos específicos de cada proyecto, así como las regulaciones y normativas aplicables en la fabricación de premoldeados.
                        </p>
                        <img src="./assets/img/soluciones/premoldeado/Premoldeadox600.jpg" alt="imagen Solucion"/>
                        <img src="./assets/img/soluciones/premoldeado/Premoldeado2x600.jpg" alt="imagen Solucion"/>
                        <img src="./assets/img/soluciones/premoldeado/Premoldeado3x600.jpg" alt="imagen Solucion"/>
                    </div>
                    <div className="contenBeneficioLogos">
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/liviano.svg" alt="MasLiviano"/>
                            <p>Reduce 30% el peso final, haciendo una estructura mucho más liviana</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/corrosion.svg" alt="Anticorrosivo"/>
                            <p>La corrosión no afecta a la estructura haciendola más duradera</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/resistente.svg" alt="Resistente"/>
                            <p>x3 veces más resistente</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/termico.svg" alt="Resistente"/>
                            <p>Reduce los efectos de la dilatación térmica en las estructuras</p>
                        </div>
                    </div>
                </div>
            </div>
        </StyleSolutionPremoldeadoView>
        <Documentation/>
        <TableBarton/>
        <Prices/>
        </>
    )
}

const StyleSolutionPremoldeadoView = styled.div`
   h2{
        color: var(--colorTerciario);
        font-size: 2.2rem;
        margin: 2rem 0rem;
        @media (min-width: 768px){
            margin: 5rem 0rem;
            text-align: center;
            font-size: 2.8rem;
        }
    }
    .banner{
        margin-top: var(--alturaNavBarMobile);
        width: 100%;
        @media (min-width: 992px){
            margin-top: var(--alturaNavBar);
        }
        img{
            width: 100%;
        }
    }
    .containerBeneficioSolucion{
        @media (min-width: 768px){
            display: grid;
            grid-template-columns: 70% 30%;
            margin-bottom: 5rem;
        }
    }
    .contenInfo{
        p{
            font-weight: 600;
            padding-right: 2rem;
            font-size: 0.9rem;
            line-height: 2;
        }
        span{
            font-size: 1.2rem;
            color: var(--colorTerciario);
            font-weight: 900;
        }
        img{
            width: 100%;
            padding: 2rem;
        }
        @media (min-width: 992px) {
            p{
                font-weight: 600;
                font-size: 1.1rem;
                line-height: 2.5;
            }
            img{
                width: 13rem;
                padding: 1rem;
            }
        }
        @media (min-width: 1200px) {
            img{
                width: 16rem;
                padding: 1rem;
            }
        }
        @media (min-width: 1400px) {
            img{
                width: 18rem;
                padding: 1rem;
            }
        }
    }
    .contenBeneficioLogos{
        background-color: var(--colorFondoMain);
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
                width: 10rem;
            }
        @media (min-width: 768px){
            border-radius: 2rem;
            width: 100%;
            img{
                width: 9rem;
            }
        }
    }
    .beneficioSolucion{
        text-align: center;
        margin: 2rem 0rem;
        p{
            font-size: 1.3rem;
            font-weight: 600;
            text-align: center;
            color: #000;
            margin: 0rem;
        }
        @media (min-width: 768px){
            width: 100%;
            padding: 2rem;
            align-items: center;
            display: flex;
            flex-direction: column;
            margin: 0rem;
        }
    }
`