import styled from "styled-components";
import Calculating from "../calculating/Calculating";

export default function BenefitsFlat(){
    return(
        <BenefitsFlatStyle>
            <div className="benefitFlatDiv">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="containerBenefitFLat">
                                <h2>Ventajas de barton 1000 para pisos de hormigon</h2>
                                <div className="contanierImgAhorro">
                                    <img src="/assets/img/benefitsFlat/ahorro.png" alt="ahorro 50% para pisos industriales" />
                                </div>
                                <h4>Mallas:</h4>
                                <p>Para una misma aplicación, se utiliza una mayor separación entre barras Barton que en acero, debido a su mayor resistencia. Esto no solo ahorra en tiempos de mano de obra sino que también el costo es mucho menor ya que se utilizan menor cantidad de barras. Teniendo en cuenta además el gran diferencial en costos de flete y remanejo en obra</p>
                                <div className="">
                                    <div className="row containerMallas">
                                        <div className="col-12 col-md-6">
                                            <div className="containerImgMallas">
                                                <img src="/assets/img/benefitsFlat/malla-barton.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="containerImgMallas">
                                                <img src="/assets/img/benefitsFlat/malla-acero.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="contenInfo">
                                    <div className="contenInfoInd">
                                        <h4>Barras de Unión:</h4>
                                        <p>Las barras Barton permiten mayor separación y en algunos casos menor diámetro que con acero</p>
                                    </div>
                                    <div className="contenInfoInd">
                                        <h4>Pasadores:</h4>
                                        <p>Se utilizan las mismas distancias y diámetros que en el acero</p>
                                    </div>
                                </div>
                                <div className="containerImgInfo">
                                    <img src="/assets/img/benefitsFlat/pasador-acero.png" alt="" />
                                    <img src="/assets/img/benefitsFlat/pasador-barton.png" alt="" />
                                </div>
                    
                            </div>
                        </div>
                    </div>
                </div>
                <Calculating/>
            </div>
        </BenefitsFlatStyle>
    )
}

const BenefitsFlatStyle = styled.div`
    h2{
        text-align: center;
        @media (min-width: 768px){
            margin-top: 7rem;
        }
    }
    .benefitFlatDiv{
        background-color: var(--colorFondoMain);
    }

    .containerBenefitFLat{
        margin: 3rem 0rem;
    }

    .contanierImgAhorro{
        text-align: center;
        width: 100%
    }
    .contanierImgAhorro img {
        padding: 2rem 0rem;
        width: 100%;
        @media(min-width: 992px){
            margin: 3rem 0rem;
            width: 80%;
        }
    }
    p{
        line-height: 2.2;
        font-size: 1.1rem;
    }
    .containerMallas{
        @media (min-width: 768px){
            display: flex;
            flex-direction: row-reverse;
        }
    }
    .containerImgMallas{
        width: 100%
    }
    .containerImgMallas img{
        padding: 2rem;
        width: 100%;
    }
    .contenInfo{
        margin-top: 2rem;
        @media (min-width: 768px){
            display: flex;
            margin-top: 4rem;
        }
    }
    .contenInfoInd{
        @media (min-width: 768px){
            padding: 0rem 1rem;
            width:50%;
        }
    }
    .containerImgInfo{
        width:100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 5rem;
        @media (min-width: 768px){
            flex-direction: row;
            justify-content: space-around;
        }
    }
    .containerImgInfo img {
        width: 80%;
        @media (min-width: 768px){
            width: 30%
        }
    }
`