// import { Link } from "react-router-dom";
import styled from "styled-components";

export default function Benefit(props){
    let img = props.benefitInfo.img;
    let logo = props.benefitInfo.logo;
    return(
        <StyledBenefit className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
            {/* <Link> to={props.benefitInfo.link} */}
                <div className="containerBenefitt">
                    <img src={img} alt={img}></img>
                    <div className="ventajasImagen">
                        <img src={logo} alt={logo}></img>
                        <h3>{props.benefitInfo.title}</h3>
                    </div>
                </div>
            {/* </Link> */}
        </StyledBenefit>
    )
}

const StyledBenefit = styled.a `
    margin-bottom: 30px;
    
    .containerBenefitt {
        width: 100%;
        img {
            width: 100%;
        }
    }

    .ventajasImagen {
        margin-top: 20px;
        text-align: center;
        img {
            width: 40%;
            margin: 0 auto;
            margin-bottom: 10px;
        }
        h2 {
            font-size: 1.6em;
        }
    }
`