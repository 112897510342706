import styled from "styled-components";

export default function VideoDiv() {
    return (
        <VideoStyled>
            <div className="videoDiv">
                <div className="container">
                    <div className="row" style={{alignItems: 'end'}}>
                        <div className="col-md-12 col-lg-5">
                            <div className="containerInfo">
                                <h2>MÁS RESISTENTE, LIVIANO, INOXIDABLE</h2>

                                <p> BARTON Rebar 1000 es un refuerzo de hormigón más resistente y más liviano que el acero. Diseñado para cumplir con los códigos y estándares constructivos internacionales.</p>

                                <a href="/assets/pdf/normas/Ficha Tecnica.pdf" target="_blank" rel="noreferrer noopener" className="btn btn-primary">DESCARGAR FICHA TÉCNICA</a>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-7">
                            <div className="containerVideo">
                                <iframe
                                    title="Uso de Barton"
                                    src={"https://player.vimeo.com/video/826722020?controls=0?&autopause=false&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1"}
                                    frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen>
                                </iframe>
                            </div>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </div>
                </div>
            </div>
        </VideoStyled>
    )
}

const VideoStyled = styled.div`
    .videoDiv{
         margin-top: 3.5rem;
         background-color: var(--colorFilaTabla);
         padding-top: 100px;
         padding-bottom: 80px;
         @media(min-width: 768px){
             display: flex;
             flex-direction: column;
             align-items: center;
         }
         @media(min-width: 992px){
             display: flex;
             flex-direction: row;
             align-items: center;
             justify-content: space-between;
             padding-bottom: 100px;
         }
    }
    .containerVideo{
        width: auto;
        height: 40vh;
        margin-top: 2rem;
        iframe{
            width: 100%;
            height: 100%;
        }
        @media(min-width: 768px){
            height: 50vh;
        }
        @media(min-width: 992px){
            height: 34vh;
        }
        @media (min-width: 1140px){
            height: 40vh;
        }
        @media(min-width: 1367px){
            height: 47vh;
        }
    }
    .containerInfo{
        line-height: 2.2;
        @media(min-width: 992px){
            padding: 10px 0px;
        }
        @media(min-width: 1367px){
            margin-right: 2rem;
            font-size: 4rem;
        }
    }
    .containerInfo p{
        margin-top: 2rem;
        font-size: 1.1rem;
    }
    .btn{
        padding: 10px 60px;
    }
`