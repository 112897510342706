import styled from "styled-components";

export default function DivisionImg(props){
    return(
        <StyleDivisionImg>
            <div className="containerImg">
            </div>
        </StyleDivisionImg>
    )
}

const StyleDivisionImg = styled.div`
    .containerImg {
        background-image: url("assets/img/divisionBanner/divisionHome1.jpg");
        background-image: no-repeat;
        background-position: center;
        height: 400px;
    }
    
`