import styled from "styled-components";
import TableBarton from "../../../component/tables/TableBarton";
import Prices from "../../../component/prices/Prices";
import Documentation from "../../../component/documentation/Documentation";

export default function SolutionHidroelectricasView (){
    return(
        <>
        <StyleSolutionHidroelectricasView>
            <div className="banner">
                <img src="./assets/img/soluciones/industria/bannerPlantasIndustrialesx400.jpg" alt="banner Solución de Hidroeléctricas"/>
            </div>
            <div className="container">
                <h2>VENTAJAS DEL USO DE BARRAS REFORZADAS DE FIBRA DE VIDRIO EN OBRAS DE PLANTAS INDUSTRIALES</h2>
                <div className="containerBeneficioSolucion">
                    <div className="contenInfo">
                        <p>
                            <span>Resistencia a la corrosión: </span> Las barras de fibra de vidrio son altamente resistentes a la corrosión, lo que las hace ideales para su uso en plantas industriales expuestas a ambientes agresivos, productos químicos y humedad. No se corroen ni se oxidan, lo que garantiza una mayor durabilidad y vida útil de las estructuras en comparación con el hierro de construcción.
                        </p>
                        <p>
                            <span>Ligereza: </span> Las barras de fibra de vidrio son considerablemente más ligeras que el hierro de construcción. Esto facilita su manejo, transporte e instalación durante la construcción de plantas industriales. Además, su ligereza puede reducir la carga estructural total y los requisitos de cimentación, lo que puede ayudar a reducir los costos y el tiempo de construcción.
                        </p>
                        <p>
                            <span>Aislamiento térmico y eléctrico: </span> Las barras de fibra de vidrio tienen una baja conductividad térmica y eléctrica. Esto las hace adecuadas para plantas industriales donde se requiere un buen aislamiento térmico y eléctrico, como en áreas de alta temperatura o donde hay equipos y sistemas eléctricos sensibles.
                        </p>
                        <p>
                            <span>Resistencia mecánica: </span> Las barras de fibra de vidrio tienen una excelente resistencia mecánica y son capaces de soportar cargas pesadas y tensiones sin deformarse ni romperse. Esto las convierte en una opción adecuada para la construcción de estructuras de soporte, plataformas y equipos en plantas industriales que están sujetas a cargas dinámicas y vibraciones.
                        </p>
                        <p>
                            <span>Flexibilidad de diseño: </span> Las barras de fibra de vidrio son flexibles y pueden ser moldeadas en diferentes formas y tamaños según los requisitos de diseño de la planta industrial. Esto ofrece una mayor flexibilidad en términos de personalización y permite la creación de diseños estructurales más complejos y adaptados a las necesidades específicas de la planta.
                        </p>
                        <p>
                        Es importante tener en cuenta que al utilizar barras de fibra de vidrio en la construcción de plantas industriales, se deben considerar otros factores, como las cargas de servicio, las regulaciones y normativas específicas, así como las necesidades de resistencia química y térmica de la planta en particular.
                        </p>
                        <img src="./assets/img/soluciones/industria/PlatasIndustrialesx600.jpg" alt="imagen Solucion"/>
                        <img src="./assets/img/soluciones/industria/PlatasIndustriales2x600.jpg" alt="imagen Solucion"/>
                        <img src="./assets/img/soluciones/industria/PlatasIndustriales3x600.jpg" alt="imagen Solucion"/>
                    </div>
                    <div className="contenBeneficioLogos">
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/corrosion.svg" alt="Anticorrosivo"/>
                            <p>La corrosión no afecta a la estructura haciendola más duradera</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/liviano.svg" alt="MasLiviano"/>
                            <p>Reduce 30% el peso final, haciendo una estructura mucho más liviana</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/dielectrico.svg" alt="Dieléctrico"/>
                            <p>Proporcionan un aislamiento eléctrico efectivo y minimizan el riesgo de descargas eléctricas</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/resistente.svg" alt="Resistente"/>
                            <p>x3 veces más resistente</p>
                        </div>
                    </div>
                </div>
            </div>
        </StyleSolutionHidroelectricasView>
        <Documentation/>
        <TableBarton/>
        <Prices/>
        </>
    )
}

const StyleSolutionHidroelectricasView = styled.div`
    h2{
        color: var(--colorTerciario);
        font-size: 2.2rem;
        margin: 2rem 0rem;
        @media (min-width: 768px){
            margin: 5rem 0rem;
            text-align: center;
            font-size: 2.8rem;
        }
    }
    .banner{
        margin-top: var(--alturaNavBarMobile);
        width: 100%;
        @media (min-width: 992px){
            margin-top: var(--alturaNavBar);
        }
        img{
            width: 100%;
        }
    }
    .containerBeneficioSolucion{
        @media (min-width: 768px){
            display: grid;
            grid-template-columns: 70% 30%;
            margin-bottom: 5rem;
        }
    }
    .contenInfo{
        p{
            font-weight: 600;
            padding-right: 2rem;
            font-size: 0.9rem;
            line-height: 2;
        }
        span{
            font-size: 1.2rem;
            color: var(--colorTerciario);
            font-weight: 900;
        }
        img{
            width: 100%;
            padding: 2rem;
        }
        @media (min-width: 992px) {
            p{
                font-weight: 600;
                font-size: 1.1rem;
                line-height: 2.5;
            }
            img{
                width: 13rem;
                padding: 1rem;
            }
        }
        @media (min-width: 1200px) {
            img{
                width: 16rem;
                padding: 1rem;
            }
        }
        @media (min-width: 1400px) {
            img{
                width: 18rem;
                padding: 1rem;
            }
        }
    }
    .contenBeneficioLogos{
        background-color: var(--colorFondoMain);
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
                width: 10rem;
            }
        @media (min-width: 768px){
            border-radius: 2rem;
            width: 100%;
            img{
                width: 9rem;
            }
        }
    }
    .beneficioSolucion{
        text-align: center;
        margin: 2rem 0rem;
        p{
            font-size: 1.3rem;
            font-weight: 600;
            text-align: center;
            color: #000;
            margin: 0rem;
        }
        @media (min-width: 768px){
            width: 100%;
            padding: 2rem;
            align-items: center;
            display: flex;
            flex-direction: column;
            margin: 0rem;
        }
    }
`