import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import styled from "styled-components";

export default function Tag (props){
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    let img = props.tagInfo.img;

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, [windowWidth]);

    // Función para determinar la clase de ancho
    function getColWidth(windowWidth) {
        if (windowWidth < 992) {
            return 'col-sm-6 col-md-6';
        } else {
            return 'col-xl-5';
        }
    }
    
    return (
        <>
          <div className={getColWidth(windowWidth)} style={{ width: windowWidth <992 ? '50%' : windowWidth >= 992 && windowWidth <1200 ? '25%' : '20%'}}>
            <StyledTag>
              <div className="containerTag">
                <img src={img} alt={img} />
                <div className="containerInfo">
                  <h4>{props.tagInfo.title}</h4>
                  <p>{props.tagInfo.info}</p>
                  <div className="containerButton">
                    <Link to={props.tagInfo.link} className="btn btn-primary">
                      Ver Más..
                    </Link>
                  </div>
                </div>
              </div>
            </StyledTag>
          </div>
        </>
      );
}

const StyledTag = styled.div`
    .containerTag{
        margin: 10px 0;
        border: var(--colorFondoMain) solid 2px;
        border-radius: 0px 0px 10px 10px;
        @media(min-width: 768px){
            margin: 5px;
            justify-content: space-between;
        }
    }
    .containerInfo{
        margin: 10px 0px;
        padding: 20px;
    }
    .containerInfo h4{
        font-size: 1rem;
        @media (min-width: 992px){
            font-size: 1.2rem;
            text-align: start;
        }
    }
    .containerButton{
        text-align: right;
    }
    img {
        width: 100%;
    }
`