import Tag from './Tag';
import styled from 'styled-components';

export default function TagGrups(props){
    return(
        <StyleTagGrups>
            <div className="container" style={{marginBottom: '5rem'}}>
                <h2 className='containerTitle'>TODAS LAS SOLUCIONES DE BARTON 1000</h2>
                <div className='row' style={{justifyContent: 'center'}}>
                    {props.listTag.map((data) => (
                        <Tag key={data.id} tagInfo={data}/>
                    ))}
                </div>
            </div>
        </StyleTagGrups>
    )
}

const StyleTagGrups = styled.div`
    h2 {
        text-align: center;
    }
    .containerTitle{
        color: var(--colorPrimario);
        margin-top: 5.5rem;
        @media (min-width: 768px){
            margin-top: 7rem;
            margin-bottom: 2rem;
        }
    }
`