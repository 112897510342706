import Accordion from './Accordion';
import styled from 'styled-components';

export default function AccordionsGroup(props) {
    return (
      <StyledAccordionsGroup>
        <section className='col-12 containerAccordions'>
            <div className="accordion contenAccordion" id="accordionExample">
              {props.sampleList.map((data) => (
                  <Accordion key={data.id} cardInfo={data} />
              ))}
            </div>
        </section>
      </StyledAccordionsGroup>
    );
  }

const StyledAccordionsGroup = styled.section`
  .containerAccordions{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3.5rem;
    width: 100%;
    @media (min-width: 768px){
      margin-top: 2.5rem;
    }
  }
  .contenTitle{
    font-family: var(--fuentePrimaria);
  }
  .contenAccordion{
    margin-top: 1.5rem;
    width: inherit;
    @media(min-width: 768px){
      width: 100%;
    }
  }
`