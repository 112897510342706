import GrupCertificateImport from "../CertificateImport/GrupCertificateImport";
import AccordionsGroup from "../accordions/AccordionsGroup";

export default function Documentation(){
    return(
        <div className="container">
            <GrupCertificateImport listCertificate={listCertificate}/>
            <AccordionsGroup sampleList={listPDF}/>
        </div>
    )
}

const listCertificate =[
    { id: 1, title: "Certificado de Infome de Ensayo de INTI", info:'Certificado OT N° 226-4361', donwload:"/assets/pdf/normas/CertificadoInformeEnsayo.pdf", img:'./assets/pdf/normas/CertificadoInformeEnsayo.png', with:'PDF | 572 KB'},
    { id: 2, title: "Certificado de Calibración / Medición de INTI", info:'Certificado OT N° 222-5888', donwload:"/assets/pdf/normas/CertificadoCalibracionMedicion.pdf", img:'./assets/pdf/normas/CertificadoCalibracionMedicion.png', with:'PDF | 414 KB'},
]
const listPDF = [
    { id: 1, title: "Ficha Técnica", info:'Barras de Refuerzo en Fibra de Vidrio - Ficha de Producto 2024', donwload:"/assets/pdf/normas/Ficha Tecnica.pdf"},
    { id: 2, title: "Preguntas Frecuentes Barton 1000", info:'Preguntas Frecuentes', donwload:"/assets/pdf/normas/Preguntas frecuentes BARTON.pdf"},
    { id: 3, title: "Instrucciones de Uso Seguro (MSDS)", info:'Como Manipular de Manera Segura las Barras Barton 1000', donwload: "/assets/pdf/normas/Instrucciones de uso seguro.pdf"},
    { id: 4, title: "¿Por qué Barton 1000?", info:'Beneficios de Barton 1000', donwload:"/assets/pdf/normas/Por que Barton.pdf"},
    { id: 5, title: "Norma ACI 440 3R", info:'Norma ACI 440 3R', donwload:"/assets/pdf/normas/Norma-ACI-440-3R.pdf"},
    { id: 6, title: "Norma ASTM D7957", info:'Norma ASTM D7957', donwload:"/assets/pdf/normas/Norma-ASTM D7957.pdf"},
    { id: 7, title: "Norma ISO 10406-1 2008", info:'Norma ISO 10406-1 2008', donwload:"/assets/pdf/normas/Norma-ISO-10406-1-2008.pdf"},
    { id: 8, title: "Ensayo de Fuego", info:'Ensayo de Fuego', donwload:"/assets/pdf/normas/ensayoFuego.pdf"},
];