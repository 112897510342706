import styled from "styled-components";

export default function TableBarton(){
    return(
        <StyleTableBarton className="container" style={{marginTop: '4rem'}}>
            <div className="scroll">
            <div className="contenedorTablaComparativa">
                <table className="tablaBarton">
                    <tbody>
                        <tr className="tituloBarton">
                            <td colSpan="12"><h3 style={{color: '#FFF'}}>Barton 1000 vs Acero ADN 420</h3></td>
                        </tr>
                        <tr className="subTituloBarton">
                            <td colSpan="2">BARRA</td>
                            <td colSpan="2">Diámetro Nominal (mm)</td>
                            <td colSpan="2">Sección Nominal (mm²)</td>
                            <td colSpan="2">KG/M</td>
                            <td colSpan="2">Tracción Máxima (kN)</td>
                            {/* <td colSpan="2">Resistencia al Corte (kN)</td> */}
                        </tr>
                        <tr className="subTituloBarton2 filaBartonImpar">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{borderLeft: 'solid #fff 1px'}}>Barton</td>
                            <td className='filaDateAcero'>Acero</td>
                            <td style={{borderLeft: 'solid #fff 1px'}}>Barton</td>
                            <td className='filaDateAcero'>Acero*</td>
                            {/* <td style={{borderLeft: 'solid #fff 1px'}}>Barton</td>
                            <td className='filaDateAcero'>Acero</td> */}
                        </tr>
                        <tr className="filaBartonImpar">
                            <td colSpan="2">B-5</td>
                            <td colSpan="2">5</td>
                            <td colSpan="2"> 19,8</td> 
                            <td className="filaDateBarton">0,035</td>
                            <td className='filaDateAcero'>0,108</td>
                            <td className="filaDateBarton">17,2</td>
                            <td className='filaDateAcero'>5,8</td>
                            {/* <td className="filaDateBarton">6,9</td>
                            <td className='filaDateAcero'>3,5</td> */}
                        </tr>
                        <tr className="filaBartonPar">
                            <td colSpan="2">B-6</td>
                            <td colSpan="2">6</td>
                            <td colSpan="2">31</td> 
                            <td className="filaDateBarton">0,08</td>
                            <td className='filaDateAcero'>0,222</td>
                            <td className="filaDateBarton">31,8</td>
                            <td className='filaDateAcero'>12</td>
                            {/* <td className="filaDateBarton">16,9</td>
                            <td className='filaDateAcero'>7,1</td> */}
                        </tr>
                        <tr className="filaBartonImpar">
                            <td colSpan="2">B-8</td>
                            <td colSpan="2">8</td>
                            <td colSpan="2">65,5</td> 
                            <td className="filaDateBarton">0,12</td>
                            <td className='filaDateAcero'>0,395</td>
                            <td className="filaDateBarton">66,6</td>
                            <td className='filaDateAcero'>25</td>
                            {/* <td className="filaDateBarton">22,4</td>
                            <td className='filaDateAcero'>12,7</td> */}
                        </tr>
                        <tr className="filaBartonPar">
                            <td colSpan="2">B-10</td>
                            <td colSpan="2">10</td>
                            <td colSpan="2">83,3</td>
                            <td className="filaDateBarton">0,15</td>
                            <td className='filaDateAcero'>0,617</td>
                            <td className="filaDateBarton">84,9</td>
                            <td className='filaDateAcero'>42</td>
                            {/* <td className="filaDateBarton">28</td>
                            <td className='filaDateAcero'>19,8</td> */}
                        </tr>
                        <tr className="filaBartonImpar">
                            <td colSpan="2">B-12</td>
                            <td colSpan="2">12</td>
                            <td colSpan="2">114</td>
                            <td className="filaDateBarton">0,23</td>
                            <td className='filaDateAcero'>0,888</td>
                            <td className="filaDateBarton">114,7</td>
                            <td className='filaDateAcero'>67</td>
                            {/* <td className="filaDateBarton">41,5</td>
                            <td className='filaDateAcero'>28,5</td> */}
                        </tr>
                        <tr className="filaBartonPar">
                            <td colSpan="2">B-16</td>
                            <td colSpan="2">16</td>
                            <td colSpan="2">195</td>
                            <td className="filaDateBarton">0,41</td>
                            <td className='filaDateAcero'>1,58</td>
                            <td className="filaDateBarton">195,2</td>
                            <td className='filaDateAcero'>105</td>
                            {/* <td className="filaDateBarton">65,5</td>
                            <td className='filaDateAcero'>50,7</td> */}
                        </tr>
                        <tr className="filaBartonImpar">
                            <td colSpan="2">B-20</td>
                            <td colSpan="2">20</td>
                            <td colSpan="2">295</td>
                            <td className="filaDateBarton">0,59</td>
                            <td className='filaDateAcero'>2,47</td>
                            <td className="filaDateBarton">295</td>
                            <td className='filaDateAcero'>144,6</td>
                            {/* <td className="filaDateBarton">130</td>
                            <td className='filaDateAcero'>79,2</td> */}
                        </tr>
                        <tr className="filaBartonPar">
                            <td colSpan="2">B-25</td>
                            <td colSpan="2">25</td>
                            <td colSpan="2">470</td>
                            <td className="filaDateBarton">1,06</td>
                            <td className='filaDateAcero'>3,85</td>
                            <td className="filaDateBarton">472</td>
                            <td className='filaDateAcero'>227</td>
                            {/* <td className="filaDateBarton">165</td>
                            <td className='filaDateAcero'>123,7</td> */}
                        </tr>
                    </tbody>
                </table>
                <div className="iconScroll">
                    <i className="fa-solid fa-left-right"></i>
                </div>
                </div>
            </div>
            <div className="containerCondition">
                <p>* Valores testeados: representa el fin de período elástico de material</p>
            </div>
        </StyleTableBarton>
    )
}

const StyleTableBarton = styled.div`
    .scroll{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .iconScroll{
        display: flex;
        justify-content: center;
        color: var(--colorPrimario);
        @media (min-width: 992px){
            display:none;
        }
    }
    .contenedorTablaComparativa::-webkit-scrollbar{
        height: 15px;
        border-radius: 10px;
        background-color: var(--colorSecundario);
    
    }
    .contenedorTablaComparativa::-webkit-scrollbar-thumb{
        background-color: var(--colorPrimario);
        border-radius: 10px;
        width: 5px;
    }
    .contenedorTablaComparativa{
        overflow-x: scroll;
        flex-direction: column;
        width: 100%;
        @media (min-width: 992px){
            width: 100%;
            display: flex;
            flex-direction: row ;
            justify-content: space-between;
            ::-webkit-scrollbar {display:none;} //Oculta la barra de scroll sin perder la funcion
        }
    }
    .tablaBarton{
        width: 100%;
        border-collapse:collapse;
        text-align: center;
        margin-bottom: 10px;
        @media (min-width: 768px){
            justify-self: flex-end; /* Alinear tabla a la derecha */
            margin-right: 0.4rem;
            margin-bottom: 0px;
            }
    }
    .tablaAcero{
        width: 50%;
        border-collapse:collapse;
        text-align: center;
        margin-top: 1rem;
        margin: 0 auto;
        @media (min-width: 768px){
                justify-self: flex-end; /* Alinear tabla a la derecha */
                margin-top: 0rem;

            }
    }
    .tituloBarton{
        background-color: var(--colorPrimario);
        margin: 20px;
    }
    .tituloAcero{
        background-color: var(--colorGris);
        margin: 20px;
  
    }
    .tituloBarton td{
        color: #fff;
        font-family: var(--fuenteSecundaria);
        font-weight: 700;
        font-size: 20px;
        padding: 20px;
    }
    .tituloAcero td{
        color: #fff;
        font-family: var(--fuenteSecundaria);
        font-weight: 700;
        font-size: 20px;
        padding: 20px;
    }
    .subTituloBarton{
        background-color: var(--colorPrimario);
        font-family: var(--fuenteSecundaria);
        color: #fff;
        padding: 5px;
    }
    .subTituloBarton2{
        background-color: var(--colorTerciario);
        font-family: var(--fuenteSecundaria);
        color: #fff;
        padding: 5px;
        border-bottom: solid #fff 1px;
    }
    .subTituloAcero{
        background-color: #808080;
        font-family: var(--fuenteSecundaria);
        color: #fff;    
        padding: 5px;
    }
    .subTituloBarton td{
        padding: 10px;
        width: 16.6%;
        font-weight: 600;
    }
    .subTituloAcero td{
        padding: 10px;
        width: 16.6%;
        font-weight: 600;
    }
    .filaBartonPar {
        background-color: var(--colorFilaTabla);
    }
    .filaBartonPar td{
        padding: 5px;
        font-weight: 500;
    }
    .filaBartonImpar td{
        padding: 5px;
        font-weight: 500;
    }
    .filaAceroPar {
        background-color: #efefef;
    }
    .filaAceroPar td{
        padding: 5px;
        font-weight: 500;
    }
    .filaDateBarton{
        background-color: var(--colorTerciario);
        color: #FFF;
        border-left: solid #fff 1px;
    }
    .filaDateAcero{
        background-color: var(--colorGris);
        color: #FFF;
    }
    td {
        font-size: 13px;
        @media (min-width: 768px){
            font-size: 15px;
        }
    }
    .containerCondition{
        margin-top: 1.5rem;
        font-size: 13px;
        @media (min-width: 992px) {
            width: 80%;
        }
    }
    }
`