import React, { useState, useEffect } from 'react';
import axios from "axios";
import styled from "styled-components";

export default function Footer() {
    const [branch, setBranch] = useState([]); // ESTO ES PARA USAR CON API DE Branch.

    useEffect(() => {
        fetchBranch(); // ESTO ES PARA USAR CON API DE Branch.
    }, []);

    const fetchBranch = async () => {
        try {
            const response = await axios.get('https://admin.leiten.com.ar/api/getAllBranches');
            const branches = response.data.slice(1); // Excluye el primer elemento
            branches[0].tel_fijo = '+54 9 11 21836522';
            setBranch(branches); // Establece Sucursales obtenidos desde la API en el estado
        } catch (error) {
            console.error('Error fetching currency:', error);
        }
    };

    return(
        <StyledFooter>
            <footer className="containerFooter">
                <div className="contenFooter">
                    <div className="contenInfo">
                        <h5>Comuníquese con Barton 1000</h5>
                        <div className="row">
                        <div className="col-6"> 
                            <ul style={{paddingLeft: '0px'}}>
                                {branch.slice(0, Math.ceil(branch.length / 2)).map(objeto => (
                                    <li className="contenSucursal" key={objeto.id}>
                                        <p className='title'><strong>{objeto.nombre}</strong></p>
                                        <p>{objeto.direccion} - {objeto.localidad} - {objeto.prov.nombre}</p>
                                        <p>{objeto.tel_fijo} / {objeto.tel_movil}</p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-6">
                            <ul style={{paddingLeft: '0px'}}>
                                {branch.slice(Math.ceil(branch.length / 2)).map(objeto => (
                                    <li className="contenSucursal" key={objeto.id}>
                                        <p className='title'><strong>{objeto.nombre}</strong></p>
                                        <p>{objeto.direccion} - {objeto.localidad} - {objeto.prov.nombre}</p>
                                        <p>{objeto.tel_fijo} / {objeto.tel_movil}</p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </footer>
        </StyledFooter>
    )
};

const StyledFooter = styled.footer`
    .containerFooter{
        display: flex;
        justify-content: flex-end;
        background-image: url('./assets/img/divisionBanner/divisionHomeDesenfoque.jpg');
        background-image: no-repeat;
        background-position: center;
        @media(min-width: 768px){
            padding: var(--paddinLateral);
            height: auto;
        }
    }
    .contenFooter{
        //backdrop-filter: blur(3px);
        /* min-width: 40%; */
        color: #fff;
    }
    .contenInfo{
        background-color: var(--colorTerciario);
        padding: 2rem 1rem;
        //opacity: 0.9;
        @media(min-width: 768px) {
            padding: 80px;
        }
    }
    .containerSucursal{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .contenSucursal p{
        color: #fff;
        font-size: 0.8rem;
    }
    .title {
        margin-top: 40px;
    }
    .title strong{
        font-size: 1.2rem;
    }
    h5 {
        color: #fff;
    }
`