import styled from "styled-components";
import TableBarton from "../../../component/tables/TableBarton";
import Prices from "../../../component/prices/Prices";
import Documentation from "../../../component/documentation/Documentation";

export default function SolutionHidroelectricasView (){
    return(
        <>
        <StyleSolutionHidroelectricasView>
            <div className="banner">
                <img src="./assets/img/soluciones/hidroelectricas/bannerHidroelectricasx400.jpg" alt="banner Solución de Hidroeléctricas"/>
            </div>
            <div className="container">
                <h2>VENTAJAS DEL USO DE BARRAS REFORZADAS DE FIBRA DE VIDRIO EN OBRAS Hidroeléctricas</h2>
                <div className="containerBeneficioSolucion">
                    <div className="contenInfo">
                        <p>
                            <span>Resistencia a la corrosión: </span> Las barras de fibra de vidrio son altamente resistentes a la corrosión, lo que las hace ideales para su uso en obras hidroeléctricas expuestas al agua y a ambientes corrosivos. No se corroen ni se oxidan, lo que garantiza una mayor durabilidad y vida útil de las estructuras.
                        </p>
                        <p>
                            <span>Ligereza: </span> Las barras de fibra de vidrio son mucho más ligeras que el hierro de construcción. Esto facilita su manipulación, transporte e instalación durante la construcción de las obras hidroeléctricas. Además, su ligereza puede reducir la carga estructural total y los costos asociados con la cimentación y los soportes.
                        </p>
                        <p>
                            <span>Resistencia dieléctrica: </span> Las barras de fibra de vidrio tienen una alta resistencia dieléctrica, lo que las convierte en una opción segura para su uso en obras hidroeléctricas. Proporcionan un aislamiento eléctrico efectivo y minimizan el riesgo de descargas eléctricas en entornos donde se generan y transmiten grandes cantidades de energía.
                        </p>
                        <p>
                            <span>Durabilidad y resistencia: </span> Las barras de fibra de vidrio son extremadamente duraderas y resistentes. Son capaces de soportar cargas mecánicas y tensiones sin deformarse ni romperse, incluso en entornos con grandes presiones hidrostáticas. Esto las hace adecuadas para su uso en estructuras como compuertas, turbinas y canalizaciones.
                        </p>
                        <p>
                            <span>Baja interferencia electromagnética: </span> Las barras de fibra de vidrio tienen una baja conductividad eléctrica, lo que reduce la interferencia electromagnética en las obras hidroeléctricas. Esto es particularmente importante para garantizar un funcionamiento óptimo de los equipos y sistemas de control y protección utilizados en la generación y distribución de energía.
                        </p>
                        <p>
                        Es importante tener en cuenta que al utilizar barras de fibra de vidrio en obras hidroeléctricas, se deben considerar otros factores, como el diseño estructural, los requisitos de carga y las regulaciones y normativas específicas aplicables a este tipo de obras.
                        </p>
                        <img src="./assets/img/soluciones/hidroelectricas/Hidroelectricasx600.jpg" alt="imagen Solucion"/>
                        <img src="./assets/img/soluciones/hidroelectricas/Hidroelectricas2x600.jpg" alt="imagen Solucion"/>
                        <img src="./assets/img/soluciones/hidroelectricas/Hidroelectricas3x600.jpg" alt="imagen Solucion"/>
                    </div>
                    <div className="contenBeneficioLogos">
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/corrosion.svg" alt="Anticorrosivo"/>
                            <p>La corrosión no afecta a la estructura haciendola más duradera</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/liviano.svg" alt="MasLiviano"/>
                            <p>Reduce 30% el peso final, haciendo una estructura mucho más liviana</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/dielectrico.svg" alt="Dieléctrico"/>
                            <p>Proporcionan un aislamiento eléctrico efectivo y minimizan el riesgo de descargas eléctricas</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/resistente.svg" alt="Resistente"/>
                            <p>x3 veces más resistente</p>
                        </div>
                    </div>
                </div>
            </div>
        </StyleSolutionHidroelectricasView>
        <Documentation/>
        <TableBarton/>
        <Prices/>
        </>
    )
}

const StyleSolutionHidroelectricasView = styled.div`
    h2{
        color: var(--colorTerciario);
        font-size: 2.2rem;
        margin: 2rem 0rem;
        @media (min-width: 768px){
            margin: 5rem 0rem;
            text-align: center;
            font-size: 2.8rem;
        }
    }
    .banner{
        margin-top: var(--alturaNavBarMobile);
        width: 100%;
        @media (min-width: 992px){
            margin-top: var(--alturaNavBar);
        }
        img{
            width: 100%;
        }
    }
    .containerBeneficioSolucion{
        @media (min-width: 768px){
            display: grid;
            grid-template-columns: 70% 30%;
            margin-bottom: 5rem;
        }
    }
    .contenInfo{
        p{
            font-weight: 600;
            padding-right: 2rem;
            font-size: 0.9rem;
            line-height: 2;
        }
        span{
            font-size: 1.2rem;
            color: var(--colorTerciario);
            font-weight: 900;
        }
        img{
            width: 100%;
            padding: 2rem;
        }
        @media (min-width: 992px) {
            p{
                font-weight: 600;
                font-size: 1.1rem;
                line-height: 2.5;
            }
            img{
                width: 13rem;
                padding: 1rem;
            }
        }
        @media (min-width: 1200px) {
            img{
                width: 16rem;
                padding: 1rem;
            }
        }
        @media (min-width: 1400px) {
            img{
                width: 18rem;
                padding: 1rem;
            }
        }
    }
    .contenBeneficioLogos{
        background-color: var(--colorFondoMain);
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
                width: 10rem;
            }
        @media (min-width: 768px){
            border-radius: 2rem;
            width: 100%;
            img{
                width: 9rem;
            }
        }
    }
    .beneficioSolucion{
        text-align: center;
        margin: 2rem 0rem;
        p{
            font-size: 1.3rem;
            font-weight: 600;
            text-align: center;
            color: #000;
            margin: 0rem;
        }
        @media (min-width: 768px){
            width: 100%;
            padding: 2rem;
            align-items: center;
            display: flex;
            flex-direction: column;
            margin: 0rem;
        }
    }
`