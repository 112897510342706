import Benefit from "./Benefit";
import styled from "styled-components";

export default function BenefitsGrup(props){
    return(
        <StyledBenefitGrup className="container">
            <div className="row">
                <div className="col-12">
                    <h2 className="containerTitle">¿POR QUÉ BARTON EN TU PRÓXIMA OBRA?</h2>
                </div>
                {props.listBenefits.map((data) => (
                    <Benefit key={data.id} benefitInfo={data}/>
                ))}
            </div>
        </StyledBenefitGrup>
    )
}

const StyledBenefitGrup = styled.div`
    .containerTitle {
        text-align: center;
        margin: 80px 0;
        @media (min-width: 768px){
            margin-top: 7rem;
        }
    }

`