import styled from "styled-components"
// import BannerPage from "../../component/banner/BannerPage"
import TagsGrupComplet from "../../component/tags/TagsGrupComplet";

export default function SolutionsView() {
    return (
        <StyledSolutionView>
            {/* <BannerPage datosBanner={datosBanner}/> */}
            <div className="containerSolutions">
                <TagsGrupComplet listTag={listTag}/>
            </div>
        </StyledSolutionView>
    )
}

const listTag = [
    { id: 1, title: "Pisos Industriales", img:'./assets/img/soluciones/solucion.jpg', info:'Las barras de refuerzo son resistentes a la corrosión, a diferencia de las fabricadas con materiales tradicionales, que eventualmente se oxidaran y agrietarán el concreto.', atr1:'./assets/img/benefits/corrosion.png', atr2:'./assets/img/benefits/resistente.png', atr3:'./assets/img/benefits/flexible.png', atr4:'./assets/img/benefits/liviano.png', textatr1:'Primer beneficio: Anticorrosivo', textatr2:'Segundo beneficio: 3 veces más Resistente', textatr3:'Tercero beneficio: ahorre costo de Trasnporte', textatr4:'Cuarto beneficio: 7 veces más Liviano'},
    { id: 2, title: "Infraestructura", img:'./assets/img/soluciones/solucion3.jpg', info:'Las barras de refuerzo son resistentes a la corrosión, a diferencia de las fabricadas con materiales tradicionales, que eventualmente se oxidaran y agrietarán el concreto.', atr1:'./assets/img/benefits/corrosion.png', atr2:'./assets/img/benefits/resistente.png', atr3:'./assets/img/benefits/flexible.png', atr4:'./assets/img/benefits/liviano.png', textatr1:'Primer beneficio: Anticorrosivo', textatr2:'Segundo beneficio: 3 veces más Resistente', textatr3:'Tercero beneficio: ahorre costo de Trasnporte', textatr4:'Cuarto beneficio: 7 veces más Liviano'},
    { id: 3, title: "Obras Hidroelectricas", img:'./assets/img/soluciones/solucion4.jpg', info:'Las barras de refuerzo son resistentes a la corrosión, a diferencia de las fabricadas con materiales tradicionales, que eventualmente se oxidaran y agrietarán el concreto.', atr1:'./assets/img/benefits/corrosion.png', atr2:'./assets/img/benefits/resistente.png', atr3:'./assets/img/benefits/flexible.png', atr4:'./assets/img/benefits/liviano.png', textatr1:'Primer beneficio: Anticorrosivo', textatr2:'Segundo beneficio: 3 veces más Resistente', textatr3:'Tercero beneficio: ahorre costo de Trasnporte', textatr4:'Cuarto beneficio: 7 veces más Liviano'},
    { id: 4, title: "Premoldeados", img:'./assets/img/soluciones/solucion2.jpg', info:'Las barras de refuerzo son resistentes a la corrosión, a diferencia de las fabricadas con materiales tradicionales, que eventualmente se oxidaran y agrietarán el concreto.', atr1:'./assets/img/benefits/corrosion.png', atr2:'./assets/img/benefits/resistente.png', atr3:'./assets/img/benefits/flexible.png', atr4:'./assets/img/benefits/liviano.png', textatr1:'Primer beneficio: Anticorrosivo', textatr2:'Segundo beneficio: 3 veces más Resistente', textatr3:'Tercero beneficio: ahorre costo de Trasnporte', textatr4:'Cuarto beneficio: 7 veces más Liviano'},
];

// const datosBanner = [
//     {img: './assets/img/divisionBanner/divisionHome1.jpg'}
// ]

const StyledSolutionView = styled.div`
    margin-top: var(--alturaNavBar);
    .containerSolutions{
        padding: var(--paddinLateralMobile);
        /* @media(min-width: 768px){
            padding: var(--paddinLateral)
        } */
    }
    section h3{
        
    }
`