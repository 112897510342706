import styled from "styled-components";
import TableBarton from "../../../component/tables/TableBarton";
import Prices from "../../../component/prices/Prices";
import Documentation from "../../../component/documentation/Documentation";

export default function SolutionInfraestructurasView (){
    return(
        <>
        <StyleSolutionInfraestructurasView>
            <div className="banner">
                <img src="./assets/img/soluciones/infraestructuras/bannerInfraestructurasx400.jpg" alt="banner Solución de Infraestructura"/>
            </div>
            <div className="container">
                <h2>VENTAJAS DEL USO DE BARRAS REFORZADAS DE FIBRA DE VIDRIO EN OBRAS DE INFRAESTRUCTURA</h2>
                <div className="containerBeneficioSolucion">
                    <div className="contenInfo">
                        <p>
                        <span>Resistencia a la corrosión: </span> Las barras de fibra de vidrio son altamente resistentes a la corrosión. A diferencia del hierro de construcción, que puede oxidarse y debilitarse con el tiempo, las barras de fibra de vidrio no se corroen, lo que las hace ideales para estructuras expuestas a ambientes agresivos, como puentes o edificios cerca del mar.
                        </p>
                        <p>
                        <span>Mayor durabilidad: </span> Las barras de fibra de vidrio son extremadamente duraderas y tienen una vida útil más larga en comparación con el hierro de construcción. Son resistentes a la fatiga y no se deforman fácilmente bajo cargas repetidas, lo que las hace adecuadas para estructuras sujetas a movimientos sísmicos o vibraciones constantes.
                        </p>
                        <p>
                        <span>Baja conductividad térmica: </span> Las barras de fibra de vidrio tienen una baja conductividad térmica en comparación con el hierro de construcción. Esto significa que no se calientan o enfrían rápidamente en respuesta a cambios de temperatura, lo que ayuda a reducir los efectos de la dilatación térmica en las estructuras y evita la formación de grietas.
                        </p>
                        <p>
                        <span>Ligereza: </span> Las barras de fibra de vidrio son significativamente más ligeras que el hierro de construcción. Esto facilita su manejo, transporte e instalación en el lugar de trabajo. Además, su ligereza puede reducir la carga muerta en una estructura, lo que puede ser beneficioso en proyectos de gran envergadura.
                        </p>
                        <p>
                        <span>Mayor resistencia específica: </span>Las barras de fibra de vidrio tienen una alta resistencia específica, lo que significa que ofrecen una mayor resistencia por unidad de peso en comparación con el hierro de construcción. Esto permite un diseño estructural más eficiente y una reducción del peso total de la estructura sin comprometer su resistencia y capacidad de carga.
                        </p>
                        <p>
                        Es importante destacar que, si bien las barras de fibra de vidrio tienen estas ventajas, su uso puede depender de factores específicos de cada proyecto y de las regulaciones y normas locales aplicables.
                        </p>
                        <img src="./assets/img/soluciones/infraestructuras/infraestructurasx600.jpg" alt="imagen Solucion"/>
                        <img src="./assets/img/soluciones/infraestructuras/infraestructuras2x600.jpg" alt="imagen Solucion"/>
                        <img src="./assets/img/soluciones/infraestructuras/infraestructuras3x600.jpg" alt="imagen Solucion"/>
                    </div>
                    <div className="contenBeneficioLogos">
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/corrosion.svg" alt="Anticorrosivo"/>
                            <p>La corrosión no afecta a la estructura haciendola más duradera</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/resistente.svg" alt="Resistente"/>
                            <p>x3 veces más resistente</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/termico.svg" alt="Resistente"/>
                            <p>Reduce los efectos de la dilatación térmica en las estructuras</p>
                        </div>
                        <div className="beneficioSolucion">
                            <img src="./assets/img/benefits/liviano.svg" alt="MasLiviano"/>
                            <p>Reduce 30% el peso final, haciendo una estructura mucho más liviana</p>
                        </div>
                    </div>
                </div>
            </div>
        </StyleSolutionInfraestructurasView>
        <Documentation/>
        <TableBarton/>
        <Prices/>
        </>
    )
}

const StyleSolutionInfraestructurasView = styled.div`
    h2{
        color: var(--colorTerciario);
        font-size: 2.2rem;
        margin: 2rem 0rem;
        @media (min-width: 768px){
            margin: 5rem 0rem;
            text-align: center;
            font-size: 2.8rem;
        }
    }
    .banner{
        margin-top: var(--alturaNavBarMobile);
        width: 100%;
        @media (min-width: 992px){
            margin-top: var(--alturaNavBar);
        }
        img{
            width: 100%;
        }
    }
    .containerBeneficioSolucion{
        @media (min-width: 768px){
            display: grid;
            grid-template-columns: 70% 30%;
            margin-bottom: 5rem;
        }
    }
    .contenInfo{
        p{
            font-weight: 600;
            padding-right: 2rem;
            font-size: 0.9rem;
            line-height: 2;
        }
        span{
            font-size: 1.2rem;
            color: var(--colorTerciario);
            font-weight: 900;
        }
        img{
            width: 100%;
            padding: 2rem;
        }
        @media (min-width: 992px) {
            p{
                font-weight: 600;
                font-size: 1.1rem;
                line-height: 2.5;
            }
            img{
                width: 13rem;
                padding: 1rem;
            }
        }
        @media (min-width: 1200px) {
            img{
                width: 16rem;
                padding: 1rem;
            }
        }
        @media (min-width: 1400px) {
            img{
                width: 18rem;
                padding: 1rem;
            }
        }
    }
    .contenBeneficioLogos{
        background-color: var(--colorFondoMain);
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
                width: 10rem;
            }
        @media (min-width: 768px){
            border-radius: 2rem;
            width: 100%;
            img{
                width: 9rem;
            }
        }
    }
    .beneficioSolucion{
        text-align: center;
        margin: 2rem 0rem;
        p{
            font-size: 1.3rem;
            font-weight: 600;
            text-align: center;
            color: #000;
            margin: 0rem;
        }
        @media (min-width: 768px){
            width: 100%;
            padding: 2rem;
            align-items: center;
            display: flex;
            flex-direction: column;
            margin: 0rem;
        }
    }
`