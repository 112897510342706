import styled from 'styled-components';
import CategorysView from './products/CategorysView';
import ProductsView from './products/ProductsView';

export default function ProductsDashboardView(){
    return (
        <StyledProductsDashboardView>
            <section className='container'>
                <div className='containerCategory'>
                    <CategorysView/>
                </div>
                <div className='containerProduct'>
                    <ProductsView/>
                </div>
            </section>
        </StyledProductsDashboardView>
    );
}

const StyledProductsDashboardView = styled.section`
    .container{
        display: flex;
        justify-content: space-around;
    }

`