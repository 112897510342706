import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PriceService from "../../services/PriceService";
import CustomerService from '../../services/CustomerService';
import toThousand from '../../services/ToThousandService';
 
export default function Calculating (){

    const [value1, setValue1] = useState('');
    const [value2, setValue2] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [result15, setResult15] = useState(null);
    const [result25, setResult25] = useState(null);
    const [result30, setResult30] = useState(null);
    const [result15Inv, setResult15Inv] = useState(null);
    const [result25Inv, setResult25Inv] = useState(null);
    const [result30Inv, setResult30Inv] = useState(null);
    const [dolar, setDolar] = useState([]);

    useEffect(() => {
        const getDolar = async () => {
            const dolarData = await CustomerService();
            setDolar(dolarData);
        };
        getDolar();
    }, []);
    
    const price = PriceService.prices //obtengo la lista de precio

    // Función para manejar el cambio en el primer input
    const handleValue1Change = (event) => {
        const newValue = Math.abs(parseFloat(event.target.value)); // Obtener el valor absoluto
        setValue1(newValue);
    };

    // Función para manejar el cambio en el segundo input
    const handleValue2Change = (event) => {
        const newValue = Math.abs(parseFloat(event.target.value)); // Obtener el valor absoluto
        setValue2(newValue);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const areInputsValid = () => {
        return value1 !== '' && value2 !== '' && selectedOption !== null;
    };

    // Función para calcular canridad de barrras
    const calculateProduct = () => {
        const num1 = parseFloat(value1);
        const num2 = parseFloat(value2);

        const calculo = (num, coef, aux) => { return  Math.floor(num / coef) === (num /coef) ? (num / coef) * aux : (Math.floor(num / coef) +1 ) * aux }

        const div15 = calculo (num1, 0.15, num2);
        const div25 = calculo (num1, 0.25, num2);
        const div30 = calculo (num1, 0.3, num2);

        const div15Inv = calculo (num2, 0.15, num1);
        const div25Inv = calculo (num2, 0.25, num1);
        const div30Inv = calculo (num2, 0.3, num1);

        setResult15(div15);
        setResult25(div25);
        setResult30(div30);

        setResult15Inv(div15Inv);
        setResult25Inv(div25Inv);
        setResult30Inv(div30Inv);
    };

    return (
        <div className="container">
            <div className="row" style={{paddingBottom: '5rem'}}>
                <h2 style={{marginTop: '5rem', textAlign: 'center'}}>Calcula tu próximo ahorro con Barton</h2>
                
                <div className="col-12 col-lg-7" style={{ marginTop: '4rem'}}>
                    <select name="diametro" id="diametro" class="form-select" onChange={handleSelectChange}>
                        <option value="" disabled selected>Selecciona una opción</option>
                        <option value="0">Diámetro 5</option>
                        <option value="1">Diámetro 6</option>
                        <option value="3">Diámetro 8</option>
                        <option value="4">Diámetro 10</option>
                        <option value="5">Diámetro 12</option>
                    </select>
                    <input  class="form-control" style={{margin: '1rem 0rem'}} type="number" 
                            value={value1} onChange={handleValue1Change} placeholder="Ingrese lado A (metros)" />
                    <input  class="form-control" style={{margin: '1rem 0rem'}} type="number"
                            value={value2} onChange={handleValue2Change} placeholder="Ingrese lado B (metros)" />

                    <div style={{marginTop: '1.5rem', textAlign: 'end', marginBottom: '1rem' }}>
                        <button type="button" class="btn btn-primary" 
                                onClick={calculateProduct} disabled={!areInputsValid()}>Calcular Metros Lineales</button>
                    </div>

                    {/* Mostrar el resultado */}
                    {result25 !== null && (
                        <StyleResult>
                            <div className="row">
                                <div className="col-12 col-lg-8" style={{display: 'flex', alignItems: 'center'}}>
                                    <div className="containerTextResult">
                                        <p style={{marginTop: '2rem'}}>Separación de 15x15 cm:  <strong>{result15 + result15Inv} metros lineales </strong></p>
                                        <p>Precio lineal Total Dolar: <strong>{toThousand(((result15 + result15Inv) * price[selectedOption].precioLineal)) } U$S </strong></p>
                                        <p>Precio lineal Total Pesos: <strong>{toThousand(((result15 + result15Inv) * price[selectedOption].precioLineal)*dolar) } $</strong></p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div className="containerImgResult">
                                        <img src="../../../assets/img/benefitsFlat/15x15.png" alt="malla 15x15" />
                                    </div>
                                </div>
                            </div>

                            <br />
                            <div className="row">
                                <div className="col-12 col-lg-8" style={{display: 'flex', alignItems: 'center'}}>
                                    <div className="containerTextResult">
                                        <p style={{marginTop: '2rem'}}>Separación de 15x25 cm:  <strong>{result15 + result25Inv} metros lineales </strong></p>
                                        <p>Precio lineal Total Dolar: <strong>{toThousand(((result15 + result25Inv) * price[selectedOption].precioLineal)) } U$S </strong></p>
                                        <p>Precio lineal Total Pesos: <strong>{toThousand(((result15 + result25Inv) * price[selectedOption].precioLineal)*dolar) } $</strong></p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div className="containerImgResult">
                                        <img src="../../../assets/img/benefitsFlat/15x25.png" alt="malla 15x25" />
                                    </div>
                                </div>
                            </div>

                            <br />
                            <div className="row">
                                <div className="col-12 col-lg-8" style={{display: 'flex', alignItems: 'center'}}>
                                    <div className="containerTextResult">
                                        <p style={{marginTop: '2rem'}}>Separación de 25x25 cm:  <strong>{result25 + result25Inv} metros lineales </strong></p>
                                        <p>Precio lineal Total Dolar: <strong>{toThousand(((result25 + result25Inv) * price[selectedOption].precioLineal)) } U$S </strong></p>
                                        <p>Precio lineal Total Pesos: <strong>{toThousand(((result25 + result25Inv) * price[selectedOption].precioLineal)*dolar) } $</strong></p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div className="containerImgResult">
                                        <img src="../../../assets/img/benefitsFlat/25x25.png" alt="malla 25x25" />
                                    </div>
                                </div>
                            </div>

                            <br />

                            <div className="row">
                                <div className="col-12 col-lg-8" style={{display: 'flex', alignItems: 'center'}}>
                                        <div className="containerTextResult">
                                            <p style={{marginTop: '2rem'}}>Separación de 30x30 cm:  <strong>{result30 + result30Inv} metros lineales </strong></p>
                                            <p>Precio lineal Total Dolar: <strong>{toThousand(((result30 + result30Inv) * price[selectedOption].precioLineal)) } U$S </strong></p>
                                            <p>Precio lineal Total Pesos: <strong>{toThousand(((result30 + result30Inv) * price[selectedOption].precioLineal)*dolar) } $</strong></p>
                                        </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div className="containerImgResult">
                                        <img src="../../../assets/img/benefitsFlat/30x30.png" alt="malla 30x30" />
                                    </div>
                                </div>
                            </div>
                        </StyleResult>
                    )}
                </div>
                <div className="col-12 col-lg-5">
                    <StyleComp>
                        <div className="containerComp">
                            <div className="contenComp">
                                
                                <h4>Costo de malla Barton de 6 x 2,15 metros de Diámetro 6</h4>
                                <p>Separación de 15x15 cm:  <strong>{176} metros lineales </strong></p>
                                <p>Precio lineal Total Dolar: <strong>{toThousand(((176) * price[1].precioLineal)) } U$S </strong></p>
                                <p>Precio lineal Total Pesos: <strong>{toThousand(((176) * price[1].precioLineal)*dolar) } $</strong></p>
                                <br />
                                <p>Separación de 15x25 cm:  <strong>{141.6} metros lineales </strong></p>
                                <p>Precio lineal Total Dolar: <strong>{toThousand(((141.6) * price[1].precioLineal)) } U$S </strong></p>
                                <p>Precio lineal Total Pesos: <strong>{toThousand(((141.6) * price[1].precioLineal)*dolar) } $</strong></p>
                                <br />
                                <p>Separación de 25x25 cm:  <strong>{105.6} metros lineales </strong></p>
                                <p>Precio lineal Total Dolar: <strong>{toThousand(((105.6) * price[1].precioLineal)) } U$S </strong></p>
                                <p>Precio lineal Total Pesos: <strong>{toThousand(((105.6) * price[1].precioLineal)*dolar) } $</strong></p>
                                <br />
                                <p>Separación de 30x30 cm:  <strong>{91} metros lineales </strong></p>
                                <p>Precio lineal Total Dolar: <strong>{toThousand(((91) * price[1].precioLineal)) } U$S </strong></p>
                                <p>Precio lineal Total Pesos: <strong>{toThousand(((91) * price[1].precioLineal)*dolar) } $</strong></p>
                            </div>
                        </div>
                    </StyleComp>
                </div>
            </div>
        </div>
    );
}

const StyleResult = styled.div`
    .containerTextResult{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .containerImgResult{
        width: 50%;
        margin: 0 auto;
        @media (min-width: 992px){
            width: 80%;
        }
    }
    img{
        width: 100%;
        @media (min-width: 992px){
            width: 80%;
        }
    }
`

const StyleComp = styled.div`
    h4 {
        margin-bottom: 2rem;
    }
    .containerComp{
        margin-top: 4rem;
        text-align: center;
        @media (min-width: 992px){
            padding: 0rem 0rem 0rem 4rem;
        }
    }
    .contenComp{
        width: 100%;
        height: auto;
        background-color: var(--colorFilaTabla);
        border-radius: 20px;
        padding: 1.8rem;
        @media (min-width: 992px){
            padding: 2rem;
        }
    }
`