import styled from "styled-components";
import TableBarton from "../../../component/tables/TableBarton";
import Normas from "../../../component/normas/Normas";

export default function BenefitsTransportableView() {
    return (
        <StyleBenefitsTransportableView>
            <img className="styleImg" src='./assets/img/benefits/trasnportable/bannerTransportablex400-3.png' alt="Ahorro en Costos de Transporte"></img>
            <div className="containerBenefit">
                
                <h2>Facilmente Trasportable</h2>
                <p className="textComparison">Lorem ipsum, dolor sit  amet consectetur adipisicing elit. Quos inventore consequuntur magnam impedit, nostrum vitae rerum quasi, dignissimos ullam sed accusantium pariatur consectetur fugit sequi debitis! Fugit, quibusdam consequuntur. Cum!
                Lorem ipsum, dolor sit  amet consectetur adipisicing elit. Quos inventore consequuntur magnam impedit, nostrum vitae rerum quasi, dignissimos ullam sed accusantium pariatur consectetur fugit sequi debitis! Fugit, quibusdam consequuntur. Cum!
                Lorem ipsum, dolor sit  amet consectetur adipisicing elit. Quos inventore consequuntur magnam impedit, nostrum vitae rerum quasi, dignissimos ullam sed accusantium pariatur consectetur fugit sequi debitis! Fugit, quibusdam consequuntur. Cum!</p>
                <img className="videoComparison" src="./assets/img/benefits/trasnportable/imagenPrincipalTransportable.png" alt="Video Liviano"></img>
            </div>
            <TableBarton/>
            <Normas/>
        </StyleBenefitsTransportableView>
    )
}

const StyleBenefitsTransportableView = styled.div`
    .styleImg{
        margin-top: var(--alturaNavBar);
        width: 100%;
    }
    .containerBenefit{
        padding: var(--paddinLateralMobile);
        margin-top: 3.5rem;
        @media(min-width: 768px){
            padding: var(--paddinLateral);
        }
    }
    .videoComparison{
        width: 100%;
        margin-top: 2rem;
    }
    .textComparison{
        margin-top: 2rem;
        text-align: justify;
        line-height: 2.2;
    }
`