import Comparison from "./Comparison";
import styled from "styled-components";

export default function ComparisonGroup(props){
    return(
        <StyleComprisonGroup>
            <div className="containerComparisonGrup">
                <div className="container">
                    {props.listComparison.map((data) => (
                        <Comparison style={{margin: 'auto'}} key={data.id} comparisonInfo={data}/>
                    ))}
                </div>
            </div>
        </StyleComprisonGroup>
    )
}

const StyleComprisonGroup = styled.div`
    .containerComparisonGrup{
        background-color: var(--colorFondoMain);
        margin-top: 3.5rem;
        padding-top: 3rem;
        padding-bottom: 3rem;
        @media (min-width: 768px){
            padding-top: 5rem;
            padding-bottom: 5rem;
        }
    }
`